import MBArrowLeft from '@assets/icons/MBArrowLeft';
import { AUDIENCE_VIEW_TABS } from '@lib/types';
import { Box, Button, Container, Divider, Group, ScrollArea, Stack, Tabs } from '@mantine/core';
import React, { Children, useState } from 'react';
import { ReactElement } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import styles from './MailberryPageWrapper.module.css';

interface MailberryPageWrapperProps {
  title: string;
  tabs?: AUDIENCE_VIEW_TABS[] | string[];
  selectedTab?: string;
  setSelectedTab?: (arg0) => void;
  showNavigateBackButton?: boolean;
  children: React.ReactElement[] | React.ReactElement;
  router?: boolean;
  prefix?: string;
  navigateBackButtonAction?: () => void;
  onClickTab?: (t: AUDIENCE_VIEW_TABS) => void;
}

interface ChildrenTabsProps {
  selectedValue: string;
}

export default function MailberryPageWrapper({
  title,
  tabs = [],
  selectedTab,
  setSelectedTab,
  showNavigateBackButton,
  children,
  router = false,
  prefix = '',
  navigateBackButtonAction,
  onClickTab,
}: MailberryPageWrapperProps) {
  const navigate = useNavigate();

  const [_selectedValue, _setSelectedValue] = useState(tabs[0]);
  const selectedValue = selectedTab || _selectedValue;
  const setSelectedValue = setSelectedTab || _setSelectedValue;

  const childrenArray = Children.toArray(children) as ReactElement[];
  const mainActionType = (<MailberryPageWrapperMainAction />).type;
  const bodyType = (<MailberryPageWrapperBody />).type;
  const tabType = (<MailberryPageWrapperTab />).type;

  const mainAction = childrenArray.find(c => c.type === mainActionType);
  const body = childrenArray.find(c => c.type === bodyType);
  let tabsc = childrenArray.filter(c => c.type === tabType);
  tabsc = tabsc.map(t => React.cloneElement(t as React.ReactElement<ChildrenTabsProps>, { selectedValue }));

  return (
    <Tabs value={selectedValue} onChange={setSelectedValue} style={{ marginBottom: 0 }} w={'100%'} h={'100%'} variant='pills'>
      <Stack gap={0} style={{ height: '100%' }}>
        <Box className={styles.menu}>
          <Stack justify='space-between' gap={40} style={{ height: '100%' }}>
            {(showNavigateBackButton || title)
              && (
                <Group justify='apart' className={styles.mainBar}>
                  <Group style={{ maxWidth: '100%', flexWrap: 'nowrap' }}>
                    {showNavigateBackButton && (
                      <Button
                        variant={'subtle'}
                        size={'sm'}
                        px={5}
                        onClick={() => {
                          if (navigateBackButtonAction) {
                            navigateBackButtonAction();
                          } else {
                            navigate(-1);
                          }
                        }}
                        color='gray'
                      >
                        <MBArrowLeft size={24} />
                      </Button>
                    )}
                    {title && <h1 className={styles.title}>{title}</h1>}
                  </Group>

                  {mainAction && <Box className={styles.actions}>{mainAction}</Box>}
                </Group>
              )}

            <Box>
              <Tabs.List w={'100%'} style={{ overflowX: 'auto', border: 'none !important', scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
                <Box style={{ display: 'flex', justifyContent: 'center' }} m={'auto'}>
                  {tabs.map((tab, idx) => {
                    const props = {};

                    if (router) {
                      props['onClick'] = () => navigate(`${prefix}/${tab.toLowerCase()}`);
                    }

                    if (onClickTab) {
                      props['onClick'] = () => onClickTab(tab);
                    }

                    return (
                      <Tabs.Tab value={tab} key={`mbwrapper${tab}${idx}`} {...props}>
                        {tab}
                      </Tabs.Tab>
                    );
                  })}
                </Box>
              </Tabs.List>
              <Divider mt={'0.25em'}></Divider>
            </Box>
          </Stack>
        </Box>
        <Box h={'95%'} w={'100%'}>
          {body && body}
          {tabsc && tabsc}
        </Box>
      </Stack>
    </Tabs>
  );
}

function MailberryPageWrapperMainAction({ children }: { children?: React.ReactNode; }) {
  return <>{children}</>;
}

function MailberryPageWrapperBody({ children }: { children?: React.ReactNode; }) {
  return <>{children}</>;
}

function MailberryPageWrapperTab(
  { value, selectedValue, children, router = false, prefix = '' }: { value?: any; selectedValue?: any; children?: React.ReactNode; router?: boolean; prefix?: string; },
) {
  if (router) {
    return (
      <Routes>
        <Route path={`${prefix}/${value.toLowerCase()}`} element={<>{children}</>} />
      </Routes>
    );
  } else if (selectedValue === value) {
    return <>{children}</>;
  } else {
    return null;
  }
}

MailberryPageWrapper.MainAction = MailberryPageWrapperMainAction;
MailberryPageWrapper.Body = MailberryPageWrapperBody;
MailberryPageWrapper.Tab = MailberryPageWrapperTab;
