import { showNotification, updateNotification } from '@mantine/notifications';
import { CheckIcon, CrossCircledIcon } from '@radix-ui/react-icons';

export enum CUSTOM_MESSAGE_STATUS {
  SUCCESS,
  WARN,
  ERROR,
}

function getNotificationProps(status: CUSTOM_MESSAGE_STATUS) {
  let color: string;
  let icon: React.ReactNode;

  switch (status) {
    case CUSTOM_MESSAGE_STATUS.SUCCESS:
      color = '#40c057';
      icon = <CheckIcon />;
      break;
    case CUSTOM_MESSAGE_STATUS.WARN:
      color = '#fcc419';
      icon = <CrossCircledIcon />;
      break;
    case CUSTOM_MESSAGE_STATUS.ERROR:
      color = '#f03e3e';
      icon = <CrossCircledIcon />;
      break;
  }

  return {
    color,
    icon,
    styles: {
      root: {
        backgroundColor: color,
        borderColor: color,

        '&::before': {
          display: 'none',
        },
      },

      title: {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '16px',
      },

      description: {
        color: '#e0f3f7',
        fontSize: '14px',
      },

      closeButton: {
        color: '#fff',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
        },
      },
    },
  };
}

export function displayNotification(
  title: string,
  message: string,
  status: CUSTOM_MESSAGE_STATUS = CUSTOM_MESSAGE_STATUS.SUCCESS,
  autoClose: number | 'never' = 5000,
) {
  const notificationProps = getNotificationProps(status);

  return showNotification({
    // position: 'top-right',
    title,
    message,
    withCloseButton: true,
    autoClose: autoClose === 'never' ? false : autoClose,
    ...notificationProps,
  });
}

export function displaySuccessNotification(
  title: string,
  message: string,
  autoClose: number | 'never' = 5000,
) {
  return displayNotification(title, message, CUSTOM_MESSAGE_STATUS.SUCCESS, autoClose);
}

export function displayErrorNotification(
  title: string,
  message: string,
  autoClose: number | 'never' = 5000,
) {
  return displayNotification(title, message, CUSTOM_MESSAGE_STATUS.ERROR, autoClose);
}

export function updateMessage(
  id: string,
  message: string,
  status: CUSTOM_MESSAGE_STATUS = CUSTOM_MESSAGE_STATUS.SUCCESS,
  autoClose: number | 'never' = 5000,
) {
  const notificationProps = getNotificationProps(status);

  return updateNotification({
    id,
    message,
    autoClose: autoClose === 'never' ? false : autoClose,
    ...notificationProps,
  });
}
