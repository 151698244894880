import parse, { DOMNode, domToReact, Element } from 'html-react-parser';
import React, { createElement, DOMAttributes, DOMElement, ReactElement } from 'react';

type Props = {
  domNode: any;
  id: string;
};

export const MailberryNode: React.FC<Props> = ({ domNode, id }) => {
  // should inject a onClick event to this domNode
  const attributes = {
    ...domNode.attribs,
    id: id,
    // onClick: () => onSelectElement(id),
    // className:domNode.attribs.className? domNode.attribs.className + customCSS:  customCSS
  };

  const nodeBase = createElement(
    domNode.name,
    attributes,
    domToReact((domNode as Element).children as DOMNode[]),
  );

  return nodeBase;
};
