// TODO:MOVE TO TYPES LIB

import { REVIEW_STATUS } from '@/sharedTypes';

export enum MESSAGE_AUTHOR {
  MANAGED_USER = 'MANAGED_USER',
  MB_PERSONNEL = 'MB_PERSONNEL',
}

/**
 * @interface ManagedAppUser
 */
interface ManagedAppUser {
  id: string;
  email: string;
  customerId: string;
  createdAt: Date;
  updatedAt: Date;
}

/**
 * @interface ChatMessage
 */
export interface ChatMessage {
  id: string;
  text: string;
  authId: string;
  sentAt: string;
  readAt: string;
  createdAt: string;
  updatedAt: string;
  createdBy: MESSAGE_AUTHOR;
  createdByIdentity: string;
}

export enum EDITOR_TYPE {
  TEXT = 'TEXT',
  HTML = 'HTML',
}

export interface EmailReview {
  id: string;
  createdAt: string;
  updatedAt: string;
  draftId: string;
  status: REVIEW_STATUS;
  content: Record<string, any>;
  subject: string;
  body: string;
  preHeader: string;
  businessAddress: string;
  styles: Record<string, any>;
}

export interface EmailPreview {
  id: string;
  body: string;
  subject: string;
  name: string;
  preHeader: string;
  fromAdress: string;
  fromName: string;
  createdAt: Date;
  AIBodyIds: string[] | null;
  AISubjectId: string | null;
  ocassion: string | null;
  deleted: boolean;
  AIPreheaderId: string | null;
  spamRatio: number;
  editor: EDITOR_TYPE;
  version: number | null;
  parentDraftId: string | null;
  updatedAt: Date;
  customerId: string;
  businessAddress: string;
  styles: Record<string, any>;
}
