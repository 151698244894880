import { Checkbox, Group, Table } from '@mantine/core';
import React from 'react';
import styles from './MailberryTable.module.css'; // Import the styles

export default function MailberryTable({
  headers,
  tableData,
  total,
  handleOnCheckOne,
  allSelected,
  setAllSelected,
  selectedItems,
  setSelectedItems,
}) {
  const handleSelectAll = e => {
    if (allSelected || total === selectedItems.length) {
      setSelectedItems([]);
      setAllSelected(false);
    } else {
      setSelectedItems(Array.from(tableData, item => (item as any).id));
      setAllSelected(true);
    }
  };

  return (
    <Table horizontalSpacing='xl' verticalSpacing='md' highlightOnHover>
      <Table.Thead>
        <Table.Tr>
          {headers.map((header, id) => {
            if (header === 'id') {
              return (
                <Table.Th key={`header-${header}`}>
                  <Checkbox
                    checked={(tableData?.length > 0 && selectedItems.length === tableData?.length) || allSelected}
                    onChange={handleSelectAll}
                  />
                </Table.Th>
              );
            } else if (id === 1) {
              return (
                <Table.Th key={`header-${id}-${header}`}>
                  <Group gap={5}>
                    {(selectedItems.length > 0 || allSelected) && (
                      <>
                        <span>{allSelected ? `${total}` : `${selectedItems.length}`} Selected -</span>
                        <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handleSelectAll}>
                          {allSelected || total === selectedItems.length ? `Unselect all ${total}` : `Select all ${total}`}
                        </span>
                        <span>-</span>
                      </>
                    )}
                    <>Email</>
                  </Group>
                </Table.Th>
              );
            } else {
              return (
                <Table.Th key={`header-${id}-${header}`}>
                  <Group gap={5}>
                    {header}
                  </Group>
                </Table.Th>
              );
            }
          })}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {tableData?.length
          ? (
            tableData.map(dataItem => (
              <Table.Tr key={dataItem.id} className={styles.tableRow}>
                {Object.keys(dataItem).map((field, index) => {
                  if (field === 'id') {
                    return (
                      <Table.Td key={`cell-${index}-${dataItem[field]}`}>
                        <Checkbox
                          checked={allSelected || selectedItems.includes(dataItem.id)}
                          onChange={e => {
                            e.stopPropagation();
                            handleOnCheckOne(dataItem.id);
                            setAllSelected(false);
                          }}
                        />
                      </Table.Td>
                    );
                  } else {
                    return (
                      <Table.Td key={`cell-${index}-${dataItem[field]}`} className={styles.rowItem}>
                        {dataItem[field]}
                      </Table.Td>
                    );
                  }
                })}
              </Table.Tr>
            ))
          )
          : (
            <Table.Tr>
              <Table.Td colSpan={headers.length} style={{ textAlign: 'center' }}>
                No content available
              </Table.Td>
            </Table.Tr>
          )}
      </Table.Tbody>
    </Table>
  );
}
