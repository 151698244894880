'use client';
import { useState } from 'react';
import { createContainer } from 'unstated-next';

export enum ReloadTokenType {
  Chat = 'chat',
  UI = 'ui',
  Comments = 'comments',
  Draft = 'draft',
}
const defaultState: Record<string, string> = {};

Object.values(ReloadTokenType).forEach(v => {
  defaultState[v] = 'a';
});

export function useReloadTokensState() {
  const setReloadToken = (_type: ReloadTokenType) => {
    const randomToken = String(Math.random());
    _setReloadTokens(prev => ({ ...prev, [_type]: randomToken }));
  };

  const [reloadTokens, _setReloadTokens] = useState(defaultState);

  const reloadAll = () => {
    setReloadToken(ReloadTokenType.UI);
    setReloadToken(ReloadTokenType.Chat);
    setReloadToken(ReloadTokenType.Comments);
    setReloadToken(ReloadTokenType.Draft);
  };

  return {
    setReloadToken,
    reloadTokens,
    reloadAll,
  };
}

const ReloadTokensState = createContainer(useReloadTokensState);

export default ReloadTokensState;
