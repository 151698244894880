import { useGetAccount } from '@/services/api/account';
import { Box, ScrollArea, Stack } from '@mantine/core';
import React from 'react';
import AccountInfo from './AccountInfo';
import OverallStats from './OverallStats';
import ReviewList from './ReviewList';

export default function Overview() {
  const { data: account, isLoading: isLoadingUser } = useGetAccount();

  return (
    <>
      <AccountInfo account={account} isLoading={isLoadingUser} />
      <OverallStats account={account} isLoading={isLoadingUser} />
      <ReviewList />
    </>
  );
}
