import React from 'react';

interface MBClickedProps {
  size: number;
  stroke?: string;
}

const MBClicked = ({ size, stroke = '#1D1D1D' }: MBClickedProps) => {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M8 8L12.9994 20L14.7743 14.7743L20 12.9994L8 8Z' stroke={stroke} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M15 15L19 19' stroke={stroke} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5 5L2 2' stroke={stroke} strokeWidth='2' strokeLinecap='round' />
      <path d='M5 9L2 12' stroke={stroke} strokeWidth='2' strokeLinecap='round' />
      <path d='M9 4.67422L11.1213 0.99999' stroke={stroke} strokeWidth='2' strokeLinecap='round' />
    </svg>
  );
};

export default MBClicked;
