import { useGetNewMessages, useGetReadMessages, useGetReadMessagesInfiniteQuery, useMarkAsRead, useSendMessage } from '@/services/api/messages';
import useCheckMobileScreen from '@/services/hooks/useMobileScreen';
import AppState from '@/services/state/AppState';
import { ReloadTokenType, useReloadTokensState } from '@/services/state/ReloadTokensState';
import PersistentStorage from '@/services/storage';
import { MESSAGE_AUTHOR } from '@/types/models';
import { ActionIcon, Avatar, Box, Center, Divider, Group, Loader, Stack, Text } from '@mantine/core';
import { Loader2 } from 'lucide-react';
import { ArrowDownIcon, ArrowLeftIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import ChatInput from './ChatInput';
import MessagesList from './MessagesList';

const sortMessagesAndRemoveDuplicates = (messages: any[]) => {
  const sorted = messages.sort((a: any, b: any) => {
    return new Date(b.sentAt).getTime() - new Date(a.sentAt).getTime();
  });
  const unique = sorted.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
  return unique;
};

const removeElementsFromSourceUsingReference = (sourceArray: any[], referenceArray: any[]) => {
  return sourceArray.filter(sourceItem => !referenceArray.some(refItem => refItem.id === sourceItem.id));
};

// createdBy
// :
// "MANAGED_USER" filter by this, exclude this
const filterArrayByObjectKey = (array: any[], key: string, value: any) => {
  return array.filter(item => item[key] !== value);
};

const addNewMessagesLocal = async (messages: any[]) => {
  if (!messages || messages.length === 0) return;
  const session = await PersistentStorage.getItem();
  const sortedAndUnique = session.chat.messages
    ? sortMessagesAndRemoveDuplicates([...session.chat.messages, ...messages])
    : sortMessagesAndRemoveDuplicates(messages);
  await PersistentStorage.updateItem(session.email, {
    chat: { ...session.chat, messages: sortedAndUnique },
  });
};

const addPastMessagesLocal = async (messages: any) => {
  if (!messages || messages.length === 0) return;
  const session = await PersistentStorage.getItem();
  const sortedAndUnique = session.chat.messages
    ? sortMessagesAndRemoveDuplicates([...messages, ...session.chat.messages])
    : sortMessagesAndRemoveDuplicates(messages);
  console.log('sortedAndUnique', sortedAndUnique, messages);
  await PersistentStorage.updateItem(session.email, {
    chat: { ...session.chat, messages: sortedAndUnique },
  });
};

const Chat = ({ styles }) => {
  const isMobile = useCheckMobileScreen();
  const appState = AppState.useContainer();
  const messagesPerRequest = 20;
  const {
    data: readMessagesPages,
    isFetchingNextPage,
    fetchNextPage,
    isLoading,
  } = useGetReadMessagesInfiniteQuery(messagesPerRequest, {
    getNextPageParam: (lastPage: any) => {
      if (lastPage && !lastPage.page) return; // no more pages
      if (Number(lastPage.page) === lastPage.totalPages) return;
      return Number(lastPage.page) + 1;
    },
  });

  const [newMessages, setNewMessages] = useState<any>([]);
  const [lastMessageId, setLastMessageId] = useState<string | null>(null);
  const [readMessages, setReadMessages] = useState<any>([]);

  const { data: readMessagesPure } = useGetReadMessages(messagesPerRequest, Math.floor(readMessages.length / messagesPerRequest) + 1);
  const { data: newMessagesServer } = useGetNewMessages(lastMessageId);
  const sendMessageServer = useSendMessage();
  const markAsReadServer = useMarkAsRead();

  const reloadTokens = useReloadTokensState();
  const updateReloadTokens = () => {
    reloadTokens.setReloadToken(ReloadTokenType.Chat);
  };

  const addPastMessagesState = messages => {
    console.log('Adding past messages to state', messages);
    if (!messages || messages.length === 0) return;
    const sortedAndUnique = sortMessagesAndRemoveDuplicates([...messages, ...readMessages]);
    const filtered = removeElementsFromSourceUsingReference(sortedAndUnique, newMessages);
    setReadMessages(prev => [...filtered]);
  };

  const addRecentMessagesState = ({ messages, deduplicateNew = true }) => {
    console.log('Adding recent messages to state', messages);
    if (!messages || messages.length === 0) return;
    const sortedAndUnique = sortMessagesAndRemoveDuplicates([...readMessages, ...messages]);
    if (deduplicateNew) {
      const filtered = removeElementsFromSourceUsingReference(sortedAndUnique, newMessages);
      setReadMessages(prev => [...filtered]);
    } else {
      setReadMessages(prev => [...sortedAndUnique]);
    }
  };

  const addNewMessagesState = messages => {
    console.log('Adding new messages to state', messages);
    if (!messages || messages.length === 0) return;
    const sortedAndUnique = sortMessagesAndRemoveDuplicates([...newMessages, ...messages]);
    const excludeManagedUser = filterArrayByObjectKey(sortedAndUnique, 'createdBy', MESSAGE_AUTHOR.MANAGED_USER);
    setNewMessages(prev => [...excludeManagedUser]);
  };

  useEffect(() => {
    console.log('readmessagespure', readMessagesPure, readMessages, readMessages.length === 0, readMessagesPure?.messages.length === 0);

    if (readMessagesPure && readMessages.length < messagesPerRequest) {
      if (readMessagesPure.messages && readMessagesPure.messages.length > 0) {
        addPastMessagesState(readMessagesPure.messages);
        addPastMessagesLocal(readMessagesPure.messages);
        // avoid duplicates
        addRecentMessagesState({ messages: readMessagesPure.messages, deduplicateNew: true });
      }
    }

    if (readMessages.length === 0 && readMessagesPure?.messages.length === 0) {
      console.log('updating last message id');
      setLastMessageId('all');
    }
  }, [readMessagesPure, readMessages.length]);

  useEffect(() => {
    if (newMessages[0]) {
      addRecentMessagesState({ messages: [], deduplicateNew: true });
    }
  }, [newMessages]);

  // update new messages from server
  useEffect(() => {
    console.log('New messages from server', newMessagesServer);
    if (newMessagesServer) {
      console.log('New messages from server', newMessagesServer);
      // clean messages from managed user
      const excludeManagedUser = filterArrayByObjectKey(newMessagesServer, 'createdBy', MESSAGE_AUTHOR.MANAGED_USER);
      console.log('excludeManagedUser', excludeManagedUser);
      addNewMessagesState(excludeManagedUser);
      addNewMessagesLocal(excludeManagedUser);
    }
  }, [newMessagesServer]);

  // update last message id
  useEffect(() => {
    if (readMessages) {
      if (readMessages.length > 0) {
        setLastMessageId(readMessages[0].id);
      }
    }
  }, [readMessages]);

  //////////////// debug =/////////
  useEffect(() => {
    console.log('lastMessageId', lastMessageId);
  }, [lastMessageId]);

  const moveMessagesToRead = (additionalMessages?: any[]) => {
    if (newMessages?.length > 0) {
      if (additionalMessages) {
        addRecentMessagesState({ messages: [...additionalMessages, ...newMessages], deduplicateNew: false });
        addNewMessagesLocal([...additionalMessages, ...newMessages]);
      } else {
        addRecentMessagesState({ messages: newMessages, deduplicateNew: false });
        addNewMessagesLocal(newMessages);
      }

      setNewMessages([]);
    } else {
      if (additionalMessages) {
        addRecentMessagesState({ messages: additionalMessages, deduplicateNew: false });
      }
    }
  };

  const handleSubmitMessage = async (message?: string, sentMessage?: any) => {
    try {
      // Send message
      if (message) {
        const sentMessage = await sendMessageServer.mutateAsync({
          text: message,
          createdBy: MESSAGE_AUTHOR.MANAGED_USER,
          createdByIdentity: appState.signupAndLogin.email ? appState.signupAndLogin.email : 'not-defined',
        });
        // move the new message to readMessages
        moveMessagesToRead([sentMessage]);
        // move the new message to local storage
        addNewMessagesLocal([sentMessage]);
      } else if (sentMessage) {
        moveMessagesToRead([sentMessage]);
        addNewMessagesLocal([sentMessage]);
      }

      markAsReadServer.mutateAsync({});
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Stack
      style={{
        // position: 'absolute',
        position: 'relative',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 10,
        ...styles,
      }}
      gap={0}
      bg={'#F4F1EB'}
    >
      <MessagesList
        newMessages={newMessages}
        readMessages={readMessages}
        isMobile={isMobile}
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        addPastMessagesState={addPastMessagesState}
        addPastMessagesLocal={addPastMessagesLocal}
        messagesPerRequest={messagesPerRequest}
        readMessagesPure={readMessagesPure}
      />
      <Group style={{ flexShrink: 0 }} w={'100%'} justify='center'>
        <ChatInput onSend={handleSubmitMessage} authId={null} isMobile={isMobile} />
      </Group>
    </Stack>
  );
};

export default Chat;
