import { openFullScreenMBModal } from '@/components/display/modals';

import Wrapper from '@/components/Wrapper';
import { formatDate, formatTime } from '@/lib/utils';
import useCheckMobileScreen from '@/services/hooks/useMobileScreen';
import { MESSAGE_TYPE } from '@/sharedTypes';
import { Box, Group, Stack, Text } from '@mantine/core';
import { EnvelopeOpenIcon, Pencil2Icon } from '@radix-ui/react-icons';
import { Children, useState } from 'react';
import { EmailReview } from '../../EmailReview';

interface MessageReviewProps {
  message: string;
  time: string;
  isSender: boolean;
  metadata?: Record<any, any>;
  type: MESSAGE_TYPE;
}

const MessageReview = ({ time, isSender, metadata }: MessageReviewProps) => {
  const [showReview, setShowReview] = useState(false);
  const date = formatDate(time);
  const isMobile = useCheckMobileScreen();

  const handleOpenReview = () => {
    // setShowReview(true);
    if (isMobile) {
      openFullScreenMBModal({ children: <EmailReview draftId={metadata?.id} />, title: 'Email Review' });
      return;
    } else {
      openFullScreenMBModal({
        children: (
          <Group justify='center'>
            <EmailReview draftId={metadata?.id} standalonePage />
          </Group>
        ),
        title: 'Email Review',
      });
    }
  };

  const handleCloseReview = () => {
    setShowReview(false);
  };

  return (
    <Group justify={isSender ? 'flex-end' : 'flex-start'}>
      <Stack
        gap={0}
        p={6}
        style={{
          width: '90%',
          backgroundColor: '#0060ff',
          // borderLeft: isSender ? '4px solid #b7b7b7' : 'none',
          // borderRight: isSender ? 'none' : '4px solid #b7b7b7',
          borderTopRightRadius: '1em',
          borderTopLeftRadius: '1em',
          borderBottomRightRadius: isSender ? 0 : '1em',
          borderBottomLeftRadius: isSender ? '1em' : 0,
          overflowWrap: 'break-word',
          whiteSpace: 'break-spaces',
          position: 'relative',
          cursor: 'pointer',
          transition: 'transform 200ms ease-in-out',
          ':hover': {
            transform: 'scale(1.01)',
          },
        }}
        onClick={handleOpenReview}
      >
        <Stack style={{ width: '80%', paddingLeft: 8 }} gap={0}>
          <Text size='lg' fw={700} lineClamp={1} c={'white'}>
            🔍{`  `}Email Review
          </Text>
          <Text size='md' c='white' lineClamp={2}>
            {metadata?.subject} - {metadata?.preHeader}
          </Text>
        </Stack>
        <Text size='xs' c='white' style={{ whiteSpace: 'nowrap', position: 'absolute', right: 0, bottom: 2, marginRight: 8 }}>
          {date}
        </Text>
      </Stack>
    </Group>
  );
};

export default MessageReview;
