import DeliveryReportStats from '@mailberry/Stats/DeliveryReportStats';
import { Group, Stack } from '@mantine/core';
import { useGetDraftById } from '@services/api/drafts';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ClicksWrapper } from './Clicks';
import OpenWrapper from './Opens';
import { OverviewWrapper } from './Overview/OverviewWrapper';
import { EmailPreviewWrapper } from './Preview';
import RecipientsWrapper from './Recipients';
import UnsubscribedWrapper from './Unsubscribed';

const stylesChildren = [
  { border: true },
  { border: false },
  { border: true },
  { border: true },
  { border: true },
  { border: true },
];
const ReportStats = ({ draftId: draftIdProp, showHeader }) => {
  const { draftId: urlDraftId } = useParams();
  const draftId = draftIdProp || urlDraftId;

  const { data: draft, isLoading } = useGetDraftById(draftId);

  return (
    (draft && draft.delivery) || isLoading
      ? (
        <DeliveryReportStats showHeader={showHeader} stylesChildren={stylesChildren}>
          <OverviewWrapper draft={draft} isLoading={isLoading} />
          <EmailPreviewWrapper draft={draft} isLoading={isLoading} />
          <RecipientsWrapper delivery={draft?.delivery} isLoadingDraft={isLoading} />
          <OpenWrapper delivery={draft?.delivery} isLoadingDraft={isLoading} />
          <ClicksWrapper delivery={draft?.delivery} isLoadingDraft={isLoading} />
          <UnsubscribedWrapper delivery={draft?.delivery} isLoadingDraft={isLoading} />
        </DeliveryReportStats>
      )
      : (
        <Group justify='center' align='center' w={'100%'} h={'100%'}>
          <h1>Report Not Found</h1>
        </Group>
      )
  );
};

export default ReportStats;
