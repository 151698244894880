import { openCSVLinkModal } from '@/components/display/modals';
import { displayErrorNotification } from '@/components/display/notifications';
import useCheckMobileScreen from '@/services/hooks/useMobileScreen';
import { formatDateToUserTimezone } from '@/sharedTypes/utils';
import { BULK_ACTIONS } from '@lib/constants';
import { exportSubscribersCSV, getClientTimezone } from '@lib/utils';
import MBEmptySubscribers from '@mailberry/Empty/MBEmptySubscribers';
import MailberryTable from '@mailberry/MailberryTable';
import { MBTableSkeleton } from '@mailberry/Table/Skeleton/MBTableSkeleton';
import { Box, Button, Checkbox, Group, Loader, Pagination, ScrollArea, Select, Space, Table } from '@mantine/core';
import { openContextModal } from '@mantine/modals';
import { useExportDeliverySubscribers, useGetDeliverySubscribers, useGetDeliverySubscribersCount } from '@services/api/delivery';
import { usePagination } from '@services/hooks/usePagination';
import AppState from '@services/state/AppState';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export const RecipientsWrapper = ({ delivery, isLoadingDraft }) => {
  const exportDeliverySubscribers = useExportDeliverySubscribers();
  const { isLoading: isLoadingCount, data: subscribersCount } = useGetDeliverySubscribersCount(delivery?.id);

  const { totalPages, page, setPage, limit, offset } = usePagination(10, subscribersCount?.total);

  const { isLoading, data: deliverySubscribers } = useGetDeliverySubscribers(delivery?.id, limit, offset);

  const pagination = { totalPages, page, setPage, limit, offset };

  return (
    <Recipients
      exportMutation={exportDeliverySubscribers}
      delivery={delivery}
      subscribersCount={subscribersCount}
      deliverySubscribers={deliverySubscribers}
      isLoading={isLoadingCount || isLoading || isLoadingDraft}
      pagination={pagination}
    >
    </Recipients>
  );
};

export default RecipientsWrapper;

export function Recipients({ exportMutation, delivery, subscribersCount, deliverySubscribers, isLoading, pagination }) {
  const appState = AppState.useContainer();

  const [selection, setSelection] = useState<string[]>([]);
  const [value, setValue] = useState<string>();

  const { totalPages, page, setPage } = pagination;

  const [allSelected, setAllSelected] = useState(false);
  const [subscribers, _setSubscribers] = useState([]);
  const isMobile = useCheckMobileScreen();

  const timeZone = getClientTimezone();
  function setSubscribers(d) {
    if (d?.length) {
      _setSubscribers(
        d.map(i => ({
          id: i.id,
          email: (
            <Link
              // to={`/subscriber/${i.subscriberId}`} # to-do see if we want to integrate subscribers
              to={'#'}
              style={{
                textDecoration: 'none',
                color: 'black',
              }}
            >
              {i.email}
            </Link>
          ),
          sentToSGAt: formatDateToUserTimezone(i.sentToSGAt, timeZone),
        })),
      );
    }
  }

  useEffect(() => {
    setSubscribers(deliverySubscribers);
  }, [deliverySubscribers]);

  const toggleRow = id => {
    setSelection(current => (current.includes(id) ? current.filter(item => item !== id) : [...current, id]));
  };

  if (isLoading) {
    return (
      <Box pr={40}>
        <ScrollArea style={{ height: 'calc(80vh - 200px)' }}>
          <MBTableSkeleton title={'Recipients'} manySelects={0} checkbox rows={[{ label: 'Email' }, { label: 'Sent At' }]} />;
        </ScrollArea>
      </Box>
    );
  }

  const handleBulkActions = () => {
    exportMutation.mutate(
      {
        id: delivery?.id,
        selectedIds: selection,
      },
      {
        onSuccess: d => {
          openCSVLinkModal(d);
        },
        onError: err => {
          displayErrorNotification('Error exporting CSV', err);
        },
      },
    );
  };

  return (
    <Box p={40}>
      <ScrollArea>
        <h2>{delivery?.sendTo} Recipients</h2>
        {selection?.length || allSelected
          ? (
            <>
              <Space h={24} />
              <Group
                maw={isMobile ? '70%' : '50%'}
              >
                <Select
                  placeholder='Bulk actions'
                  value={value}
                  onChange={setValue}
                  disabled={exportMutation.isLoading || subscribers?.length === 0}
                  clearable
                  data={[{ value: BULK_ACTIONS.EXPORT, label: BULK_ACTIONS.EXPORT }]}
                />
                {value && (
                  <Button size='md' variant={'default'} loading={exportMutation.isLoading} onClick={handleBulkActions} px={35}>
                    Confirm
                  </Button>
                )}
              </Group>
            </>
          )
          : null}
        <Space h={24} />

        {subscribers.length
          ? (
            <MailberryTable
              headers={['id', 'Subscriber', 'Sent At']}
              tableData={subscribers}
              total={subscribersCount?.total}
              handleOnCheckOne={toggleRow}
              allSelected={allSelected}
              setAllSelected={setAllSelected}
              selectedItems={selection}
              setSelectedItems={setSelection}
            />
          )
          : null}

        {!subscribers.length && <MBEmptySubscribers />}
        <Space h={24} />
        <Group justify='center'>
          <Pagination display={totalPages > 1 ? 'flex' : 'none'} value={page} onChange={setPage} total={totalPages} defaultValue={1} color='mbblue' />
        </Group>
      </ScrollArea>
    </Box>
  );
}

// const useStyles = createStyles(theme => ({
//   rowSelected: {
//     backgroundColor: theme.colorScheme === 'dark' ? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.2) : theme.colors[theme.primaryColor][0],
//   },
// }));
