import { ChatMessage, EmailPreview, MESSAGE_AUTHOR } from '@/types/models';
import { Draft } from '@prisma/client';
import { UseInfiniteQueryOptions } from '@tanstack/react-query';
import ReloadTokens, { ReloadTokenType } from '../state/ReloadTokensState';
import { genericRequest, useMailberryQuery } from './base';

export function useGetEmailPreview(id: string) {
  return useMailberryQuery<Draft>({
    f: () => {
      return genericRequest('get', `/preview`, { id });
    },
    queryKey: [id],
    options: {
      cacheTime: 0,
    },
  });
}
