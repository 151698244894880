import { BlankPage } from '@/components/display/BlankPage';
import AppState from '@/services/state/AppState';
import { Box, Container, Text } from '@mantine/core';

const CheckVersionPage = () => {
  const appState = AppState.useContainer();

  return (
    <>
      <BlankPage>
        <img width={'40%'} height={'40%'} src='/assets/mailberry-logo-simple.svg' alt='Mailberry Logo' />
        <Box style={{ textAlign: 'center' }}>There is a new version available, Please upgrade.</Box>
      </BlankPage>
    </>
  );
};

export default CheckVersionPage;
