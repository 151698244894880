import { ActionIcon, Box, Button, Group, ScrollArea, Stack, Text } from '@mantine/core';
import { closeModal, modals, openConfirmModal, openContextModal } from '@mantine/modals';
import { ArrowLeft } from 'lucide-react';

const headerWithLeftArrow = ({ title, height, onClose }: { title: string; height: number; onClose: () => void; }) => (
  <Group justify='space-between' align='center' h={height} bg='white' w='100%' gap={10} mt={0} px={10} style={{ position: 'sticky', top: 0, zIndex: 100 }}>
    <ActionIcon onClick={onClose} color='#ffffff' style={{ cursor: 'pointer' }} w={60}>
      <ArrowLeft color='#111827' style={{ width: 24, height: 24 }} />
    </ActionIcon>
    <Text size='xl' fw={600}>{title}</Text>
    <Button onClick={onClose} variant={'white'} m={0} py={0} h={'100%'} style={{ visibility: 'hidden' }}>
      <ArrowLeft color='black' size={40} />
    </Button>
  </Group>
);

export const openFullScreenMBModal = ({ children, title, id, onClose }: { children: React.ReactNode; title: string; id?: string; onClose?: () => void; }) => {
  const handleOnClose = () => {
    if (onClose) onClose();
    closeModal(id);
  };
  const height = Math.max(window.innerHeight * 0.05, 35);
  const header = headerWithLeftArrow({ title, height, onClose: handleOnClose });
  modals.open({
    children: (
      <Stack w='100vw'>
        {header}
        <Box>
          {children}
        </Box>
      </Stack>
    ),
    padding: 0,
    withCloseButton: false,
    fullScreen: true,
    transitionProps: {
      transition: 'slide-left',
    },
    withinPortal: false,
    styles: {
      overlay: {
        background: 'white',
      },
      inner: {
        height: 'calc(100dvh - (env(safe-area-inset-top) + env(safe-area-inset-bottom)) )',
        marginTop: 'env(safe-area-inset-top)',
        marginBottom: 'env(safe-area-inset-bottom)',
      },
      content: {
        height: 'calc(100%)',
        boxShadow: 'none',
      },
    },
    // h: '100dvh',
    modalId: id,
  });
};

export const openCSVLinkModal = (link: string) => {
  modals.open({
    title: 'Your link is ready',
    children: (
      <Stack gap={20} align='center'>
        <Text size='xl'>Click the link below to download your CSV file</Text>
        <Button
          variant='outline'
          color='blue'
          onClick={() => {
            window.open(link, '_blank');
          }}
        >
          Download CSV
        </Button>
      </Stack>
    ),
    centered: true,
  });
};
