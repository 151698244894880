import { Stack } from '@mantine/core';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './TabsMenuButton.module.scss';

export default function TabMenuButton({ children, route, disabled = false }: { children: ReactNode; route: string; disabled?: boolean; }) {
  const navigate = useNavigate();

  function handleClick() {
    if (disabled) return;
    navigate(route);
  }

  return (
    <Stack h={'68px'} w={'68px'} justify='center' align='center' gap={0} className={classes.tabsMenuButton} style={{ color: disabled ? 'gray' : 'initial' }} onClick={handleClick}>
      {children}
    </Stack>
  );
}
