import TabMenuButton from '@components/screens/TabsMenu/TabsMenuButton';
import { ColorSwatch, Group, Text } from '@mantine/core';
import { useGetUnreadMessages } from '@services/api/messages';
import React from 'react';
import { FaChartBar } from 'react-icons/fa';
import { FiBookOpen } from 'react-icons/fi';
import { TiMessages } from 'react-icons/ti';

export default function TabsMenu() {
  const { data: unreadMessages } = useGetUnreadMessages();

  return (
    <Group
      align='center'
      // justify={appState.installed.platform === 'ios' ? 'flex-end' : 'center'}
      justify={'center'}
      style={{ marginLeft: 'auto', marginRight: 'auto' }}
    >
      <TabMenuButton route={'/'}>
        <FiBookOpen size={24} />
        <Text>Overview</Text>
      </TabMenuButton>
      <TabMenuButton route={'/chat'}>
        {unreadMessages && unreadMessages.length > 0 && (
          <ColorSwatch
            color='red'
            style={{
              position: 'absolute',
              top: '20%',
              right: '10%',
              transform: 'translateY(-50%)',
              aspectRatio: 1,
            }}
            size={'1.75em'}
          >
            <Text c='white' size='lg' fw='bolder'>{unreadMessages.length}</Text>
          </ColorSwatch>
        )}
        <TiMessages size={24} />
        <Text>Chat</Text>
      </TabMenuButton>
      <TabMenuButton route={'/reports'}>
        <FaChartBar size={24} />
        <Text>Reports</Text>
      </TabMenuButton>
    </Group>
  );
}
