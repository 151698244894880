import { openFullScreenMBModal } from '@/components/display/modals';
import MBEmptyGeneric from '@/mailberry/Empty/MBEmptyGeneric';
import { useGetAllLastDemoReview } from '@/services/api/demo/review';
import { useGetAllLastReview } from '@/services/api/emailReview';
import { Box, Button, Card, Group, Skeleton, Stack, Text, Title } from '@mantine/core';
import { closeModal, modals } from '@mantine/modals';
import { useTour } from '@reactour/tour';
import { useEffect, useState } from 'react';
import { FaEdit, FaEye, FaTrashAlt } from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';
import { DemoEmailReview, EmailReview } from '../../EmailReview';

enum STATUS_COLORS {
  APPROVED = 'green',
  CHANGES_REQUESTED = 'red',
  READY_FOR_REVIEW = 'blue',
}
export default function ReviewList() {
  const { data, isLoading: isLoadingReviewList } = useGetAllLastDemoReview();

  const [searchParams, setSearchParams] = useSearchParams();
  const [draftId, setDraftId] = useState<string | null>();

  useEffect(() => {
    const id = searchParams.get('id');
    if (draftId !== id) {
      setDraftId(id);
    }
  });

  useEffect(() => {
    const modal = searchParams.get('modal');
    if (!modal) {
      closeModal('review');
    } else if (modal === 'review' && draftId) {
      onClickView(draftId, true);
    }
  }, [draftId]);

  const { setCurrentStep, currentStep } = useTour();

  const handleOnClose = () => {
    setSearchParams();
    setCurrentStep(4);
  };

  const onClickView = (draftId, byQueryStr = false) => {
    openFullScreenMBModal({
      children: <DemoEmailReview draftId={draftId} onClose={handleOnClose} standalonePage />,
      title: 'Review',
      id: 'review',
    });
    if (!byQueryStr) setSearchParams(`modal=review&id=${draftId}`);
  };

  return (
    <Stack gap='md' style={{ width: '100%', padding: '20px' }}>
      {isLoadingReviewList
        ? (
          Array.from({ length: 5 }).map((_, index) => <Skeleton key={index} height={100} radius='md' animate />)
        )
        : (
          <Stack gap={0}>
            <Title order={4} fw={600} pb={'md'}>
              Upcoming Campaigns
            </Title>
            <Stack gap='md'>
              {data?.data?.length === 0
                ? <MBEmptyGeneric />
                : (
                  <>
                    {data?.data?.map((review, i) => {
                      const className = i === 0 ? `first-step` : 'sixth-step';
                      return (
                        <Card className={className} key={review.id} shadow='sm' p='lg' radius='md' withBorder>
                          <Text fw={500} size='lg'>{review.subject || 'No Subject'}</Text>
                          <Text size='sm' c='dimmed'>{review.preHeader || 'No Preheader'}</Text>
                          <Group justify='space-between'>
                            <Text size='sm' style={{ color: STATUS_COLORS[review.status] }}>
                              {review.status.charAt(0).toUpperCase() + review.status.slice(1).replaceAll('_', ' ')}
                            </Text>
                            <Group justify='right' mt={0}>
                              <Button variant='light' color='blue' onClick={() => onClickView(review.id)}>
                                View
                              </Button>
                            </Group>
                          </Group>
                        </Card>
                      );
                    })}
                  </>
                )}
            </Stack>
          </Stack>
        )}
    </Stack>
  );
}
