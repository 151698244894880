import { MailberryLoader } from '@/components/display/loader/MailberryLoader';
import { Box } from '@mantine/core';
import React from 'react';
import Overview from './Overview';

export default function Dashboard() {
  return (
    <Box h={'100%'} w={'100%'}>
      <MailberryLoader visible={false} />
      <Overview />
    </Box>
  );
}
