import { displayErrorNotification } from '@/components/display/notifications';
import MailberryTable from '@/mailberry/MailberryTable';
import { useExportDeliverySubscribers, useGetDeliverySubscribers, useGetDeliverySubscribersCount } from '@/services/api/delivery';
import useCheckMobileScreen from '@/services/hooks/useMobileScreen';
import { formatDateToUserTimezone } from '@/sharedTypes/utils';
import { BULK_ACTIONS } from '@lib/constants';
import { DELIVERY_SUBSCRIBER_FILTERS } from '@lib/types';
import { exportSubscribersCSV, getClientTimezone } from '@lib/utils';
import MBEmptyUnsubscribers from '@mailberry/Empty/MBEmptyUnsubscribers';
import { MBTableSkeleton } from '@mailberry/Table/Skeleton/MBTableSkeleton';
import { Box, Button, Checkbox, Group, Loader, Pagination, ScrollArea, Select, Space, Table } from '@mantine/core';
import { openContextModal } from '@mantine/modals';
import { usePagination } from '@services/hooks/usePagination';
import AppState from '@services/state/AppState';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export const UnsubscribedWrapper = ({ delivery, isLoadingDraft }) => {
  const exportDeliverySubscribers = useExportDeliverySubscribers();
  const { isLoading: isLoadingCount, data: subscribersCount } = useGetDeliverySubscribersCount(delivery?.id, DELIVERY_SUBSCRIBER_FILTERS.UNSUBSCRIBED);

  const { totalPages, page, setPage, limit, offset } = usePagination(10, subscribersCount?.total);

  const { isLoading, data: deliverySubscribers } = useGetDeliverySubscribers(delivery?.id, limit, offset, DELIVERY_SUBSCRIBER_FILTERS.UNSUBSCRIBED);

  const pagination = { totalPages, page, setPage, limit, offset };

  return (
    <Unsubscribed
      exportMutation={exportDeliverySubscribers}
      delivery={delivery}
      subscribersCount={subscribersCount}
      deliverySubscribers={deliverySubscribers}
      isLoading={isLoadingCount || isLoading || isLoadingDraft}
      pagination={pagination}
    >
    </Unsubscribed>
  );
};

export default UnsubscribedWrapper;

export function Unsubscribed({ exportMutation, delivery, subscribersCount, deliverySubscribers, isLoading, pagination }) {
  const appState = AppState.useContainer();

  const [selection, setSelection] = useState<string[]>([]);
  const [value, setValue] = useState<string>();

  const [subscribers, _setSubscribers] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  const { totalPages, page, setPage } = pagination;
  const timeZone = getClientTimezone();

  const isMobile = useCheckMobileScreen();

  function setSubscribers(d) {
    if (d?.length) {
      _setSubscribers(
        d.map(i => ({
          id: i.id,
          email: (
            <Link
              // to={`/subscriber/${i.subscriberId}`} # to-do see if we want to integrate subscribers
              to={'#'}
              style={{
                textDecoration: 'none',
                color: 'black',
              }}
            >
              {i.email}
            </Link>
          ),
          sentToSGAt: formatDateToUserTimezone(i.sentToSGAt, timeZone),
        })),
      );
    }
  }

  useEffect(() => {
    setSubscribers(deliverySubscribers);
  }, [deliverySubscribers]);

  const toggleRow = id => {
    setSelection(current => (current.includes(id) ? current.filter(item => item !== id) : [...current, id]));
  };

  if (isLoading) {
    return (
      <Box pr={40}>
        <ScrollArea style={{ height: 'calc(80vh - 200px)' }}>
          <MBTableSkeleton title={'Unsubscribes'} manySelects={0} checkbox rows={[{ label: 'Subscriber' }, { label: 'Unsubscribed At' }]} />;
        </ScrollArea>
      </Box>
    );
  }

  const handleBulkActions = () => {
    exportMutation.mutate(
      {
        id: delivery?.id,
        filterBy: DELIVERY_SUBSCRIBER_FILTERS.UNSUBSCRIBED,
        selectedIds: selection,
      },
      {
        onSuccess: d => {
          openContextModal({
            modal: 'csv-link',
            title: 'Your link is ready',
            innerProps: {
              link: d,
            },
          });
        },
        onError: err => {
          displayErrorNotification('Error exporting CSV', err.message);
        },
      },
    );
  };

  return (
    <Box p={40}>
      <ScrollArea>
        {subscribers.length
          ? (
            <>
              <h2>{subscribersCount?.total} Unsubscribes</h2>
              {selection?.length || allSelected
                ? (
                  <>
                    <Space h={24} />
                    <Group
                      maw={isMobile ? '70%' : '50%'}
                    >
                      <Select
                        placeholder='Bulk actions'
                        value={value}
                        onChange={setValue}
                        disabled={exportMutation.isLoading || subscribers.length === 0}
                        clearable
                        data={[{ value: BULK_ACTIONS.EXPORT, label: BULK_ACTIONS.EXPORT }]}
                      />
                      {(value?.length || exportMutation.isLoading) && (
                        <Button size='md' variant={'default'} loading={exportMutation.isLoading} onClick={handleBulkActions} px={35}>
                          Confirm
                        </Button>
                      )}
                    </Group>
                  </>
                )
                : null}

              <Space h={24} />
              <MailberryTable
                headers={['id', 'Subscriber', 'Sent At']}
                tableData={subscribers}
                total={subscribersCount?.total}
                handleOnCheckOne={toggleRow}
                allSelected={allSelected}
                setAllSelected={setAllSelected}
                selectedItems={selection}
                setSelectedItems={setSelection}
              />
              <Space h={24} />
              <Group justify='center'>
                <Pagination display={totalPages > 1 ? 'flex' : 'none'} value={page} onChange={setPage} total={totalPages} defaultValue={1} color='mbblue' />
              </Group>
            </>
          )
          : <MBEmptyUnsubscribers />}
        <Space h={24} />
        <Space h={24} />
      </ScrollArea>
    </Box>
  );
}
