import ReloadTokens, { ReloadTokenType } from '@/services/state/ReloadTokensState';
import { Web } from '@/sharedTypes';
import { DemoReview } from '@prisma/client';
import { genericAuthRequest, RuntimeBackend, useMailberryMutate, useMailberryQuery } from '../base';

export function useGetAllLastDemoReview() {
  return useMailberryQuery<{ data: DemoReview[]; }>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/demo/review/all`, {}, RuntimeBackend);
    },
    options: { refetchInterval: 10000, cacheTime: 0 },
  });
}

export function useGetDemoReview(demoDraftId: string) {
  const { reloadTokens } = ReloadTokens.useContainer();
  return useMailberryQuery<{ data: DemoReview & { businessAddress: string; }; }>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/demo/review`, { demoDraftId }, RuntimeBackend);
    },
    queryKey: [reloadTokens[ReloadTokenType.Comments], demoDraftId],
    options: { cacheTime: 0 },
  });
}

export function useUpdateDemoReview() {
  return useMailberryMutate<{ data: DemoReview; message: string; }, Web.Request.UpdateDemoReview>({
    f: (accessToken, userData) => {
      return genericAuthRequest(accessToken, 'put', `/demo/review`, userData, RuntimeBackend);
    },
    reloadTokenType: ReloadTokenType.Comments,
  });
}
