import { openFullScreenMBModal } from '@/components/display/modals';
import MBEmptyGeneric from '@/mailberry/Empty/MBEmptyGeneric';
import { useGetAllLastReview } from '@/services/api/emailReview';
import { Box, Button, Card, Group, Skeleton, Stack, Text, Title } from '@mantine/core';
import { closeModal } from '@mantine/modals';
import { useEffect, useState } from 'react';
import { FaEdit, FaEye, FaTrashAlt } from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';
import { EmailReview } from '../../EmailReview';

enum STATUS_COLORS {
  APPROVED = 'green',
  CHANGES_REQUESTED = 'red',
  READY_FOR_REVIEW = 'blue',
}
export default function ReviewList() {
  const { data: allLastReview, isLoading: isLoadingReviewList } = useGetAllLastReview();
  const [searchParams, setSearchParams] = useSearchParams();
  const [draftId, setDraftId] = useState<string | null>();

  useEffect(() => {
    const id = searchParams.get('id');
    if (draftId !== id) {
      setDraftId(id);
    }
  });

  useEffect(() => {
    const modal = searchParams.get('modal');
    if (!modal) {
      closeModal('review');
    } else if (modal === 'review' && draftId) {
      onClickView({ draftId, byQueryStr: true });
    }
  }, [draftId]);

  const handleOnClose = () => {
    setSearchParams();
  };

  const onClickView = ({ draftId, byQueryStr }) => {
    openFullScreenMBModal({
      children: <EmailReview draftId={draftId} onClose={handleOnClose} standalonePage />,
      title: 'Review',
      id: 'review',
      onClose: handleOnClose,
    });
    if (!byQueryStr) setSearchParams(`modal=review&id=${draftId}`);
  };

  return (
    <Stack gap='md' style={{ width: '100%', padding: '20px' }}>
      {isLoadingReviewList
        ? (
          Array.from({ length: 5 }).map((_, index) => <Skeleton key={index} height={100} radius='md' animate />)
        )
        : (
          <Stack gap={0}>
            <Title order={4} fw={600} pb={'md'}>
              Upcoming Campaigns
            </Title>
            <Stack gap='md'>
              {allLastReview?.length === 0
                ? <MBEmptyGeneric />
                : (
                  <>
                    {allLastReview?.map(review => (
                      <Card key={review.id} shadow='sm' p='lg' radius='md' withBorder>
                        <Text fw={500} size='lg'>{review.subject || 'No Subject'}</Text>
                        <Text size='sm' c='dimmed'>{review.preHeader || 'No Preheader'}</Text>
                        <Group justify='space-between'>
                          <Text size='sm' style={{ color: STATUS_COLORS[review.status] }}>
                            {review.status.charAt(0).toUpperCase() + review.status.slice(1).replaceAll('_', ' ')}
                          </Text>
                          <Group justify='right' mt={0}>
                            <Button variant='light' color='blue' onClick={() => onClickView({ draftId: review.draftId, byQueryStr: '' })}>
                              View
                            </Button>
                          </Group>
                        </Group>
                      </Card>
                    ))}
                  </>
                )}
            </Stack>
          </Stack>
        )}
    </Stack>
  );
}
