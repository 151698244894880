import { Box, Group, Stack, Text, Tooltip } from '@mantine/core';
import React from 'react';
import { CiLink } from 'react-icons/ci';

export function ClickedLinkItem({ link }) {
  return (
    <Group justify='space-between' px={40}>
      <Group align='center' justify='center' gap={4}>
        <CiLink size={16} />
        <Text fs={'16'} fw={400} lh={'24px'} c={'mbneutrals.7'}>
          <a
            href={link.link}
            target='_blank'
            rel='noopener noreferrer'
            style={{
              color: '#707070',
              textDecoration: 'none',
            }}
          >
            {link.link.length > 28
              ? (
                <Tooltip label={link.link}>
                  <Text>{link.link.substring(0, 28)}...</Text>
                </Tooltip>
              )
              : (
                link.link
              )}
          </a>
        </Text>
      </Group>
      <Text fs={'20'} fw={400} lh={'32px'} c={'mbneutrals.9'}>
        {link.clicks}
      </Text>
    </Group>
  );
}
