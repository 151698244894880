import { Box, Group, ScrollArea, Stack, Text } from '@mantine/core';
import { ArrowLeftIcon, Cross1Icon } from '@radix-ui/react-icons';

interface Props {
  comments: string[];
  onClose: () => void;
  mobile?: boolean;
  styles?: React.CSSProperties;
}

const CommentList: React.FC<Props> = ({ comments, onClose, mobile, styles }) => {
  if (comments?.length === 0) return null;

  return (
    <Stack
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        flex: 1,
        overflowY: 'auto',
        padding: '1rem',
        backgroundColor: 'white',
        border: mobile ? '1px solid rgba(229, 231, 235, 0.4)' : 'none',
        ...styles,
      }}
    >
      <Group style={{ marginBottom: '1rem' }} align='center' gap='1rem'>
        {mobile && <Box style={{ width: '1.25rem' }} />}
        <Text
          style={{
            textAlign: 'center',
            margin: 0,
            flex: 1,
          }}
          size={mobile ? 'lg' : 'xl'}
        >
          Comments
        </Text>
        {mobile && <Cross1Icon style={{ height: '1.25em', width: '1.25em' }} onClick={onClose} />}
      </Group>
      <ScrollArea
        style={{
          overflowY: 'auto',
        }}
      >
        {comments.map((c: string, idx: number) => {
          return <CommentBox key={idx} comment={c} />;
        })}
      </ScrollArea>
    </Stack>
  );
};

export default CommentList;

const CommentBox = ({ comment }: { comment: string; }) => {
  return (
    <Group
      style={{
        border: '2px solid rgba(229, 231, 235, 0.4)',
        overflowWrap: 'break-word',
        whiteSpace: 'break-spaces',
        borderRadius: '0.5rem',
      }}
      p={15}
    >
      <Text>{comment}</Text>
    </Group>
  );
};
