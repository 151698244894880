import { displayErrorNotification } from '@/components/display/notifications';
import { postVerify } from '@/services/api/auth';
import { stopNotifications } from '@/services/pushNotifications';
import PersistentStorage from '@/services/storage';
import { Capacitor } from '@capacitor/core';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { genericAuthRequest } from '../api/base';
import AppState from '../state/AppState';
import { useReloadTokensState } from '../state/ReloadTokensState';

export function useAuth() {
  const navigate = useNavigate();
  const appState = AppState.useContainer();
  const authMutation = useMutation({ mutationFn: postVerify });

  // const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  async function signIn(email: string, code: string, onSignInSuccess: () => void) {
    try {
      let accessToken;
      appState.userAndCustomer.setIsLoading(true);

      // Bypass OTP login for development and testing
      if (email === 'store@test.com' || process.env.NEXT_PUBLIC_NODE_ENV === 'development') {
        accessToken = code;
      } else {
        const { data } = await authMutation.mutateAsync({ email, code });
        const { error, message } = data?.data;
        accessToken = data?.data?.accessToken;

        if (error) {
          displayErrorNotification('Error', message, 5000);
        }
      }

      if (!accessToken) return;

      appState.signupAndLogin.setAccessToken(accessToken);
      PersistentStorage.saveItem({
        email,
        accessToken: accessToken,
        refreshToken: '',
        updatedAt: new Date().toUTCString(),
        chat: {},
      });

      onSignInSuccess();
    } catch (error: any) {
      console.error('SignIn Error:', error);

      // let message;
      // if (error instanceof AxiosError) {
      //   message = error.response?.data.message;
      // } else {
      //   message = error;
      // }

      // displayErrorNotification("Couldn't sign in", message, 5000);
      // if (error.code === 'UserNotConfirmedException') {
      //   setErrorMessage('USER NOT CONFIRMED');
      // } else {
      //   setErrorMessage(error.message);
      // }
    } finally {
      appState.userAndCustomer.setIsLoading(false);
    }
  }

  async function signOut(onSignOutDone?: () => void) {
    appState.userAndCustomer.setIsLoading(true);

    try {
      localStorage.clear();
      await PersistentStorage.deleteAll();
      appState.resetAll();
    } catch (error) {
      console.error('Error during sign out:', error);
    } finally {
      document.cookie = '';
      appState.userAndCustomer.setIsLoading(false);

      if (Capacitor.isNativePlatform()) {
        stopNotifications(appState.signupAndLogin.accessToken);
      }

      if (onSignOutDone) {
        onSignOutDone();
      }
      navigate('/login');
    }
  }

  async function setAccessToken(email: string, accessToken: string) {
    appState.userAndCustomer.setIsLoading(true);
    appState.signupAndLogin.setAccessToken(accessToken);
    PersistentStorage.saveItem({
      email,
      accessToken: accessToken,
      refreshToken: '',
      updatedAt: new Date().toUTCString(),
      chat: {},
    });
    appState.userAndCustomer.setIsLoading(false);
  }
  // const refreshSession = async () => {
  //   if (isRefreshing) {
  //     console.log('Already refreshing');
  //     return;
  //   }
  //   setIsRefreshing(true);
  //   console.log('refreshSession');
  //   appState.userAndCustomer.setIsLoading(true);
  //   const INVALID_REFRESH_TOKEN = 'NotAuthorizedException';
  //   const NO_USER = 'No current user';

  //   try {
  //     // This used to rely on cognito session before to recover a local session
  //     const accessToken = localStorage.getItem('accessToken');
  //     const email = localStorage.getItem('email');
  //     if (!accessToken || !email) return signOut();

  //     await PersistentStorage.updateItem(email, {
  //       accessToken,
  //       updatedAt: new Date().toUTCString(),
  //     });

  //     appState.signupAndLogin.setAccessToken(accessToken);
  //   } catch (error: any) {
  //     console.error('Refresh Session Error:', error);
  //     if (error.code === INVALID_REFRESH_TOKEN || error === NO_USER) {
  //       signOut();
  //     }
  //   } finally {
  //     setIsRefreshing(false);
  //     appState.userAndCustomer.setIsLoading(false);
  //   }
  // };

  return {
    clearErrorMessage: () => setErrorMessage(null),
    errorMessage,
    isLoading: appState.userAndCustomer.isLoading,
    isAuthenticated: appState.signupAndLogin.isAuthenticated,
    accessToken: appState.signupAndLogin.accessToken,
    signIn,
    signOut,
    setAccessToken,
    // refreshSession,
  };
}
