import { openCSVLinkModal } from '@/components/display/modals';
import { displayErrorNotification } from '@/components/display/notifications';
import { BULK_ACTIONS } from '@/lib/constants';
import { getClientTimezone } from '@/lib/utils';
import MailberryTable from '@/mailberry/MailberryTable';
import { useExportDeliverySubscribers, useGetDeliveryLinks, useGetDeliverySubscribersWhoClickedALink, useGetDeliverySubscribersWhoClickedALinkCount } from '@/services/api/delivery';
import useCheckMobileScreen from '@/services/hooks/useMobileScreen';
import { formatDateToUserTimezone } from '@/sharedTypes/utils';
import MBArrowLeft from '@assets/icons/MBArrowLeft';
import { DELIVERY_SUBSCRIBER_FILTERS } from '@lib/types';
import MBEmptyClicks from '@mailberry/Empty/MBEmptyClicks';
import { MBTableSkeleton } from '@mailberry/Table/Skeleton/MBTableSkeleton';
import { Box, Button, Flex, Group, Pagination, ScrollArea, Select, Space, Stack, Table } from '@mantine/core';
import { openContextModal } from '@mantine/modals';
import { usePagination } from '@services/hooks/usePagination';
import AppState from '@services/state/AppState';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const implementedFeatureDate = 1702496858954;

export const ClicksWrapper = ({ delivery, isLoadingDraft }) => {
  const { isLoading, data: links } = useGetDeliveryLinks(delivery?.id);

  return <Clicks delivery={delivery} links={links} isLoading={isLoading || isLoadingDraft}></Clicks>;
};

export function Clicks({ delivery, links, isLoading }) {
  const [linkSelected, setLinkSelected] = useState(null);

  if (isLoading) {
    return (
      <Box pr={40}>
        <ScrollArea style={{ height: 'calc(80vh - 200px)' }}>
          <MBTableSkeleton title={'Clicks'} manySelects={0} checkbox rows={[{ label: 'Link' }, { label: 'Clicks' }]} />;
        </ScrollArea>
      </Box>
    );
  }

  const rows = links?.map(link => {
    if (link.link === '') return;
    const showNewFeature = new Date(link.createdAt) > new Date(implementedFeatureDate);

    return (
      <Table.Tr key={link.id}>
        <Table.Td>
          <a
            href={link.link}
            target='_blank'
            rel='noopener noreferrer'
            style={{
              color: '#1D1D1D',
              textDecoration: 'none',
            }}
          >
            {link.link}
          </a>
        </Table.Td>
        <Table.Td
          onClick={() => {
            if (showNewFeature) {
              setLinkSelected(link);
            } else {
              openContextModal({
                modal: 'content-not-available',
                title: 'Content Not Available',
                innerProps: {
                  text: `This section is only available for emails created after ${dayjs(implementedFeatureDate).format('MM/DD/YYYY')}`,
                },
              });
            }
          }}
          style={{ cursor: 'pointer' }}
        >
          {link.clicks} Clicks
        </Table.Td>
      </Table.Tr>
    );
  });

  return (
    <Box p={40}>
      {linkSelected ? <LinkClickedOverview delivery={delivery} linkSelected={linkSelected} setLinkSelected={setLinkSelected} /> : (
        <ScrollArea>
          {links.length
            ? (
              <>
                <h2>{links?.length > 0 ? links.map(i => i.clicks).reduce((a, b) => a + b) : 0} Clicks</h2>
                <Space h={24} />
                <Table verticalSpacing='sm'>
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th>Link</Table.Th>
                      <Table.Th>Clicks</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  {!!links.length && <tbody>{rows}</tbody>}
                </Table>
              </>
            )
            : <MBEmptyClicks />}
          <Space h={24} />
          <Space h={24} />
        </ScrollArea>
      )}
    </Box>
  );
}

const LinkClickedOverview = ({ delivery, linkSelected, setLinkSelected }) => {
  const exportMutation = useExportDeliverySubscribers();
  const { isLoading: isLoadingCount, data: deliverySuscribersCount } = useGetDeliverySubscribersWhoClickedALinkCount(linkSelected.id);
  const pagination = usePagination(10, deliverySuscribersCount?.total);
  const { isLoading, data: deliverySubscribers } = useGetDeliverySubscribersWhoClickedALink(linkSelected.id, pagination.limit, pagination.offset);

  return (
    <>
      <Stack align='flex-start' gap={12}>
        <Flex gap={20} align={'center'}>
          <Button
            variant='subtle'
            color='gray'
            px={5}
            onClick={() => {
              setLinkSelected(null);
            }}
          >
            <MBArrowLeft size={24} />
          </Button>
          <h2 style={{ margin: 0 }}>{linkSelected.clicks} Clicked</h2>
        </Flex>
      </Stack>
      <ClicksTable
        isLoading={isLoading || isLoadingCount}
        linkSelected={linkSelected.link}
        exportMutation={exportMutation}
        subscribersCount={deliverySuscribersCount}
        deliverySubscribers={deliverySubscribers}
        pagination={pagination}
        delivery={delivery}
      />
    </>
  );
};

const ClicksTable = ({ isLoading, linkSelected, exportMutation, subscribersCount, pagination, deliverySubscribers, delivery }) => {
  const appState = AppState.useContainer();
  const { totalPages, page, setPage } = pagination;
  const [selection, setSelection] = useState<string[]>([]);
  const [allSelected, setAllSelected] = useState(false);
  const [value, setValue] = useState<string>();
  const timeZone = getClientTimezone();
  const isMobile = useCheckMobileScreen();

  const toggleRow = id => {
    setSelection(current => (current.includes(id) ? current.filter(item => item !== id) : [...current, id]));
  };

  function setSubscribers(deliverySubscribers) {
    if (deliverySubscribers?.length) {
      return deliverySubscribers.map(deliverySubscriber => ({
        id: deliverySubscriber.id,
        email: (
          <Link
            // to={`/subscriber/${i.subscriberId}`} # to-do see if we want to integrate subscribers
            to={'#'}
            style={{
              textDecoration: 'none',
              color: 'black',
            }}
          >
            {deliverySubscriber.email}
          </Link>
        ),
        clickedAt: formatDateToUserTimezone(deliverySubscriber.clickedAt, timeZone),
      }));
    }
  }

  if (isLoading) {
    return <MBTableSkeleton title={linkSelected} manySelects={1} checkbox rows={[{ label: 'Link' }, { label: 'Clicks' }]} />;
  }

  const handleBulkActions = () => {
    exportMutation.mutate(
      {
        id: delivery?.id,
        filterBy: DELIVERY_SUBSCRIBER_FILTERS.CLICKED,
        selectedIds: selection,
      },
      {
        onSuccess: d => {
          openCSVLinkModal(d);
        },
        onError: err => {
          displayErrorNotification('Error exporting subscribers', err.message);
        },
      },
    );
  };

  return (
    <Box p={40}>
      <h2>{linkSelected}</h2>
      {selection?.length || allSelected
        ? (
          <>
            <Space h={24} />
            <Group
              maw={isMobile ? '70%' : '50%'}
            >
              <Select
                placeholder='Bulk actions'
                value={value}
                onChange={setValue}
                disabled={exportMutation.isLoading || deliverySubscribers?.length === 0}
                clearable
                data={[{ value: BULK_ACTIONS.EXPORT, label: BULK_ACTIONS.EXPORT }]}
              />
              {value && (
                <Button size='md' variant={'default'} loading={exportMutation.isLoading} onClick={handleBulkActions} px={35}>
                  Confirm
                </Button>
              )}
            </Group>
          </>
        )
        : null}
      <Space h={24} />
      <MailberryTable
        headers={['id', 'Subscriber', 'Clicked At']}
        tableData={setSubscribers(deliverySubscribers)}
        total={subscribersCount?.total}
        handleOnCheckOne={toggleRow}
        allSelected={allSelected}
        setAllSelected={setAllSelected}
        selectedItems={selection}
        setSelectedItems={setSelection}
      />
      <Space h={24} />
      <Pagination display={totalPages > 1 ? 'flex' : 'none'} value={page} onChange={setPage} total={totalPages} defaultValue={1} color='mbblue' />
    </Box>
  );
};
