import Layout from '@/components/Layout';
import ProtectedDemoRoute from '@/components/ProtectedDemoRoute';
import ProtectedRoute from '@/components/ProtectedRoute';
import AdminChat from '@/components/screens/AdminChat';
import CheckVersionPage from '@/components/screens/Auth/CheckVersion';
import SignIn from '@/components/screens/Auth/SignIn';
import SignUpDemo from '@/components/screens/Auth/SignUpDemo';
import Dashboard from '@/components/screens/Dashboard';
import DemoDashboard from '@/components/screens/DemoDashboard';
import { EmailReview } from '@/components/screens/EmailReview';
import ReportStats from '@/components/screens/EmailStats';
import { getVersion } from '@/services/api/auth';
import { initNotifications } from '@/services/pushNotifications';
import AppState from '@/services/state/AppState';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import DemoLayout from '@components/DemoLayout';
import Chat from '@components/screens/Chat';
import Reports from '@components/screens/Dashboard/Reports';
import { Transition } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

// right way of logging things on Mobile device iOS Android
let log = console.log.bind(console);

const Main = () => {
  const [isCurrVersion, setIsCurrVersion] = useState(true);
  let navigate = useNavigate();
  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      // Example url: https://beerswift.app/tabs/tab2
      // slug = /tabs/tab2
      const slug = event.url.split('.ai').pop();
      if (slug) {
        navigate(slug);
      }
      log('SLUG', event.url);
      // alert(event.url)
      // If no match, do nothing - let regular routing
      // logic take over
    });
  }, []);

  const checkVersionData = useQuery(
    ['version'],
    () => getVersion(),
    {
      refetchInterval: 10000,
    },
  );

  const checkVersion = async () => {
    const currVersion = checkVersionData?.data?.data?.data?.version;
    // bypass version check for web app
    console.log('Capacitor.isNativePlatform()', Capacitor.isNativePlatform());
    if (Capacitor.isNativePlatform()) {
      console.log('2');
      const app = await App.getInfo();
      log('APP-INSTALLED-VERSION', app.version, 'APP-CURRENT-VERSION', currVersion, app.version === currVersion);
      if (currVersion !== undefined && app?.version !== currVersion) {
        setIsCurrVersion(false);
      } else {
        setIsCurrVersion(true);
      }
    }
  };

  useEffect(() => {
    checkVersion();
  }, [checkVersionData?.data?.data?.data?.version]);

  const appState = AppState.useContainer();

  useEffect(() => {
    (async () => {
      const { platform } = await Device.getInfo();
      appState.installed.setPlatform(platform);
    })();
  }, []);

  useEffect(() => {
    if (appState.signupAndLogin.accessToken && Capacitor.isNativePlatform()) {
      initNotifications(appState.signupAndLogin.accessToken, ({ data }) => {
        // Function logic attached to "action performed" push notification action listener
        if (data?.type === 'message') {
          navigate('/chat');
        } else if (data?.type === 'review') {
          navigate(`/?modal=review&id=${data.id}`);
        } else if (data?.type === 'stats') {
          navigate(`/?modal=report&id=${data.id}`);
        }
      });
    }
  }, [appState.signupAndLogin.accessToken]);

  return (
    <main
      className={'tour'}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'calc(100dvh - (env(safe-area-inset-top) + env(safe-area-inset-bottom)) )',
        marginTop: 'env(safe-area-inset-top)',
        marginBottom: 'env(safe-area-inset-bottom)',
        backgroundColor: 'white',
      }}
    >
      {isCurrVersion
        ? (
          <Routes>
            <Route
              path='/login'
              element={<SignIn />}
            />

            <Route
              path='/signup-demo'
              element={<SignUpDemo />}
            />

            <Route
              path='/review'
              element={
                <Layout>
                  <EmailReview draftId='' standalonePage />
                </Layout>
              }
            />

            <Route
              path='/admin-chat'
              element={
                <Layout scroll={false}>
                  <AdminChat />
                </Layout>
              }
            />

            <Route
              path='/demo'
              element={
                <ProtectedDemoRoute>
                  <DemoLayout>
                    <DemoDashboard />
                  </DemoLayout>
                </ProtectedDemoRoute>
              }
            />
            <Route
              path='/demo/chat'
              element={
                <ProtectedDemoRoute>
                  <Layout scroll={false}>
                    <DemoDashboard />
                  </Layout>
                </ProtectedDemoRoute>
              }
            />
            <Route
              path='/demo/reports'
              element={
                <ProtectedDemoRoute>
                  <Layout>
                    <DemoDashboard />
                  </Layout>
                </ProtectedDemoRoute>
              }
            />

            <Route
              path='/'
              element={
                <ProtectedRoute>
                  <Layout>
                    <Dashboard />
                  </Layout>
                </ProtectedRoute>
              }
            />

            <Route
              path='/chat'
              element={
                <ProtectedRoute>
                  <Layout scroll={false}>
                    <Transition
                      mounted={true}
                      transition='pop-bottom-right'
                      duration={200}
                    >
                      {styles => <Chat styles={styles} />}
                    </Transition>
                  </Layout>
                </ProtectedRoute>
              }
            />

            <Route
              path='/reports'
              element={
                <ProtectedRoute>
                  <Layout>
                    <Reports />
                  </Layout>
                </ProtectedRoute>
              }
            />

            <Route
              path='/stats/:draftId'
              element={
                <ProtectedRoute>
                  <Layout>
                    <ReportStats showHeader={true} draftId={null} />
                  </Layout>
                </ProtectedRoute>
              }
            />
          </Routes>
        )
        : <CheckVersionPage />}
    </main>
  );
};

export default Main;
