import { Space, Stack } from '@mantine/core';
import Image from 'next/image';
import React from 'react';

const MBEmptySubscribers = ({ children }: { children?: React.ReactNode; }) => {
  return (
    <Stack align='center'>
      <Space h={58} />
      <Image width='257' height='210' alt='empty content' src='/img/empty/subscribers.png' />
      {children && (
        <>
          <Space h={48} />
          {children}
        </>
      )}
    </Stack>
  );
};

export default MBEmptySubscribers;
