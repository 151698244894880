import { AUDIENCE_VIEW_TABS, EMAILS_VIEW_TABS, REPORT_VIEW_TABS, SETTINGS_VIEW_TABS } from './types';

export const MAILBERRY_SUPPORT_EMAIL = 'support@mailberry.ai';
export const DEFAULT_EMAILS_VIEW_TAB = EMAILS_VIEW_TABS.ALL;
export const DEFAULT_REPORT_VIEW_TAB = REPORT_VIEW_TABS.OVERVIEW;
export const DEFAULT_AUDIENCE_VIEW_TAB = AUDIENCE_VIEW_TABS.SUBSCRIBERS;
export const DEFAULT_SETTINGS_VIEW_TAB = SETTINGS_VIEW_TABS.PROFILE;
export const ENV_DEVELOPMENT = 'development';
export const DEFAULT_BUSINESS_ADDRESS = '[Update your business address in your Email Settings]';

export const BULK_ACTIONS = {
  EXPORT: 'Export',
};
export const AXIOS_ERROR_TIMEOUT_CODE = 'ECONNABORTED';

export enum USER_STATUS {
  ACTIVE = 'ACTIVE',
  SERVICE_DISABLED = 'SERVICE_DISABLED',
  REJECTED = 'REJECTED',
  FREE_TIER_EXCEEDED = 'FREE_TIER_EXCEEDED',
}

export const COLOR_INPUT_DROPDOWN_Z_INDEX = 6935;

export const DEFAULT_EDITOR_STYLES = {
  backgroundColor: '#ffffff',
  editorColor: '#ffffff',
  fontColor: '#000000',
  fontSize: 16,
  borderWidth: 2,
  borderColor: '#dddddd',
  borderRadius: 12,
};
