import { genericAuthRequest } from '@/services/api/base';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { Capacitor } from '@capacitor/core';
import { initializeApp } from 'firebase/app';
import { GetTokenOptions } from 'firebase/messaging';

import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
let log = console.log.bind(console);

export const initNotifications = async (accesToken: string, actionPerformedListenerFn: (PushNotificationSchema) => void) => {
  // Request permission to use push notifications
  // iOS will prompt user and return if they granted permission or not
  // Android will just grant without prompting
  PushNotifications.requestPermissions().then(result => {
    if (result.receive === 'granted') {
      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register();
    } else {
      // Show some error
    }
  });

  // On success, we should be able to receive notifications
  PushNotifications.addListener('registration', async (token: Token) => {
    // save token in db
    log('registration');
    await upsertSubscriptionOnBackend(accesToken, token.value);
  });

  // Some issue with our setup and push will not work
  PushNotifications.addListener('registrationError', (error: any) => {
    log('registrationError');
  });

  // Show us the notification payload if the app is open on our device
  PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
    log('pushNotificationReceived');
  });

  // Method called when tapping on a notification
  PushNotifications.addListener('pushNotificationActionPerformed', (action: ActionPerformed) => {
    log('pushNotificationActionPerformed', action);
    actionPerformedListenerFn(action.notification);
  });
};

// Deletes firebase token on Android and unregister APNS on iOS
export const stopNotifications = async (accesToken: string) => {
  PushNotifications.unregister();
  await unsubscribeUserFromPushService(accesToken);
};

// const addMobileListeners = () => {
//   FirebaseMessaging.addListener('notificationReceived', event => {
//     log('notificationReceived: ', { event });
//   });
//   FirebaseMessaging.addListener('notificationActionPerformed', event => {
//     log('notificationActionPerformed: ', { event });
//   });
// };

// const requestPermissions = async () => {
//   let permStatus = await FirebaseMessaging.checkPermissions();
//   if (permStatus.receive === 'prompt') {
//     permStatus = await FirebaseMessaging.requestPermissions();
//   }
//   if (permStatus.receive !== 'granted') {
//     error('User denied notifications!');
//     return false;
//   }
//   return true;
// };

// const getFirebaseToken = async () => {
//   const options: GetTokenOptions = {
//     vapidKey: firebaseConfig.vapidKey,
//   };

//   if (Capacitor.getPlatform() === 'web') {
//     options.serviceWorkerRegistration = await navigator.serviceWorker.register('sw.js');
//   }
//   const { token } = await FirebaseMessaging.getToken(options);
//   log(token);
//   return token;
// };

// export const initNotifications = async (accesToken: string) => {
//   if (Capacitor.isNativePlatform()) {
//     initializeApp(firebaseConfig);
//   }

//   const permissions = await requestPermissions();

//   if (!permissions) return unsubscribeUserFromPushService(accesToken);

//   // request FCM token
//   const firebaseToken = await getFirebaseToken();

//   // save token in db
//   await upsertSubscriptionOnBackend(accesToken, firebaseToken);

//   // web listener are handled by service worker
//   if (Capacitor.isNativePlatform()) {
//     addMobileListeners();
//   }
// };

const unsubscribeUserFromPushService = async (accessToken: string) => {
  try {
    log('DELETE FROM DB .User is not subscribed');
    await genericAuthRequest(accessToken, 'DELETE', '/notifications');
  } catch (error) {
    log('Update Subscription failed', error);
  }
};

const upsertSubscriptionOnBackend = async (
  accessToken: string,
  token: string,
) => {
  try {
    await genericAuthRequest(accessToken, 'POST', '/notifications', { subscription: token });
  } catch (error) {
    log('Update Subscription failed', error);
  }
};
