import React from 'react';
import { useNotification } from './NotificationProvider';

const Notification = () => {
  const { notification, hideNotification } = useNotification();

  if (!notification) return null;

  const { message, type } = notification;

  return (
    <div className='fixed top-2 left-1/2 transform -translate-x-1/2 bg-green-500 text-white px-4 py-2 rounded-md shadow-lg z-10'>
      {message}
    </div>
  );
};

export default Notification;
