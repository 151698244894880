import BerryIcon from '@/assets/icons/BerryIcon';
import { filterObjectsById } from '@/lib/utils';
import { useGetNewMessagesAdmin, useGetReadMessagesAdminInfiniteQuery, useMarkAsReadAdmin, useSendMessageAdmin } from '@/services/api/admin';
import useInView from '@/services/hooks/useInView';
// import { initNotifications } from '@/services/pushNotifications';
import AppState from '@/services/state/AppState';
import PersistentStorage from '@/services/storage';
import { MESSAGE_AUTHOR } from '@/types/models';
import { ArrowLeftIcon } from '@radix-ui/react-icons';
import { ArrowDownIcon } from '@radix-ui/react-icons';
import { Loader2 } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { NewMessagesIndicator } from '../Chat/NewMessagesIndicator';

import MessageBox from '@/components/screens/Chat/MessageBox';
import { ActionIcon, Avatar, Box, Center, Divider, Group, Loader, Stack, Text, Transition } from '@mantine/core';
import ChatInput from '../Chat/ChatInput';

const ChatBox = ({ currentChat, onExit, authId, num }: { currentChat: Record<string, any>; onExit: () => void; authId: string; num: number; }) => {
  const { data: readMessagesPages, isFetchingNextPage, fetchNextPage, isLoading } = useGetReadMessagesAdminInfiniteQuery(20, currentChat.authId, {
    getNextPageParam: (lastPage: any) => {
      if (lastPage && !lastPage.page) return;
      // no more pages
      if (Number(lastPage.page) === lastPage.totalPages) return;

      return Number(lastPage.page) + 1;
    },
  });
  const [newMessages, setNewMessages] = useState<any>([]);
  const [lastMessageId, setLastMessageId] = useState<string | null>(null);

  // useEffect(() => {
  //   if (appState.signupAndLogin.accessToken) {
  //     console.log('Init notifications');
  //     initNotifications(appState.signupAndLogin.accessToken);
  //   }
  // }, []);

  const sendMessage = useSendMessageAdmin();
  const markAsRead = useMarkAsReadAdmin();

  // When the chat is opened, the scroll moves to the last message
  const messagesBottomRef = useRef<HTMLDivElement>(null);

  const newMessagesRef = useRef<HTMLDivElement>(null);
  const newMessagesInView = useInView(newMessagesRef);

  const [readMessages, setReadMessages] = useState<any>([]);

  useEffect(() => {
    if (newMessagesInView) {
      markAsRead.mutateAsync({ authId: currentChat.authId });
    }
  }, [newMessagesInView]);

  useEffect(() => {
    messagesBottomRef.current?.scrollIntoView({ behavior: 'instant' });
  }, [readMessagesPages?.pages[0].page]);

  // Request more messages by scrolling up
  const messagesTopRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(messagesTopRef);

  useEffect(() => {
    if (isInView) {
      console.log('VISTO');
      fetchNextPage();
    }
  }, [isInView]);

  const moveMessagesToRead = (additionalMessages?: any) => {
    if (newMessages?.data?.length > 0) {
      if (additionalMessages) {
        setReadMessages([...additionalMessages, ...newMessages.data, ...readMessages]);
      } else {
        setReadMessages([...newMessages.data, ...readMessages]);
      }
      setNewMessages([]);
    } else {
      if (additionalMessages) {
        setReadMessages([...additionalMessages, ...readMessages]);
      }
    }
  };

  const updatePastMessages = (pastMessages: any) => {
    const filtered = [...filterObjectsById(readMessages, pastMessages), ...readMessages];
    const sorted = filtered.sort((a: any, b: any) => {
      return new Date(b.sentAt).getTime() - new Date(a.sentAt).getTime();
    });
    setReadMessages(sorted);
  };

  const handleSubmitMessage = async (message?: string, sentMessage?: any) => {
    try {
      // Send message
      if (message) {
        const sentMessage = await sendMessage.mutateAsync({ text: message, createdBy: MESSAGE_AUTHOR.MB_PERSONNEL, createdByIdentity: 'not-defined', authId: currentChat.authId });
        // move the new message to readMessages
        moveMessagesToRead([sentMessage]);
      } else if (sentMessage) {
        moveMessagesToRead([sentMessage]);
      }

      markAsRead.mutateAsync({ authId: currentChat.authId });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (readMessages.length === 0 && readMessagesPages) {
      setReadMessages(readMessagesPages.pages.flatMap(e => e.messages));
    } else if (readMessagesPages) {
      updatePastMessages(readMessagesPages.pages.slice(1).flatMap(e => e.messages));
    }
    if (readMessagesPages?.pages[0].totalPages < 1 && newMessages.length < 1) {
      setLastMessageId('all');
    }
  }, [readMessagesPages]);

  let _newMessages = useGetNewMessagesAdmin(authId, lastMessageId, num);

  const updateLastMessageId = (readMessages: any) => {
    if (readMessages && readMessages.length > 0) {
      setLastMessageId(readMessages[0].id);
    }
  };

  useEffect(() => {
    if (readMessages) {
      updateLastMessageId(readMessages);
      // make a copy of newMessages
    }
  }, [readMessages]);

  useEffect(() => {
    if (_newMessages.data !== newMessages?.data) {
      if (newMessages?.data) {
        const newMessagesCopy = { ..._newMessages };
        const readMessagesCopy = [...readMessages];
        // filter out the messages that are already in readMessages
        const filteredNewMessages = filterObjectsById(readMessagesCopy, newMessagesCopy?.data);
        // set newMessages to the filtered messages
        newMessagesCopy.data = filteredNewMessages;
        setNewMessages(newMessagesCopy);
        return;
      }
      setNewMessages(_newMessages);
    }
  }, [_newMessages.data]); // Only trigger when _newMessages.data changes

  const isNewMessagesInView = useInView(newMessagesRef);

  useEffect(() => {
    if (isNewMessagesInView && newMessages?.data?.length > 0) {
      markAsRead.mutateAsync({ authId: currentChat.authId });
    }
  }, [isNewMessagesInView]);

  const handleScrollToNewMessages = () => {
    if (!newMessagesInView) {
      messagesBottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const onExitChatBox = () => {
    onExit();
  };

  return (
    <Stack
      style={{
        height: '100%',
        position: 'relative',
      }}
      gap={0}
      bg={'#F4F1EB'}
    >
      <Box
        style={{
          width: '100%',
          zIndex: 10,
          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
          backgroundColor: '#ffffff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '12px 16px',
          '@media (maxWidth: 768px)': {
            display: 'none',
          },
          height: '6dvh',
        }}
      >
        <Group gap='xs' align='center'>
          <ActionIcon onClick={onExitChatBox} color='#ffffff' style={{ cursor: 'pointer' }}>
            <ArrowLeftIcon color='#111827' style={{ width: 24, height: 24 }} />
          </ActionIcon>
          <Group gap='xs' align='center'>
            <Avatar
              src={currentChat.logoUrl}
              alt='Chat Logo'
              radius='xl'
              size={32}
              color='#374151'
            />
            <Text fw={600} size='sm' c='#111827'>
              {currentChat.name}
            </Text>
          </Group>
        </Group>
      </Box>

      <NewMessagesIndicator
        newMessages={newMessages}
        newMessagesInView={newMessagesInView}
        handleScrollToNewMessages={handleScrollToNewMessages}
      />

      <Box
        style={{
          height: 'calc(95% - 6dvh)',
          display: 'flex',
          flexDirection: 'column-reverse',
          gap: '0.5rem',
          padding: '1rem',
          position: 'relative',
          overflowY: 'auto',
        }}
        pb={0}
      >
        <Box ref={messagesBottomRef}></Box>
        <Box ref={newMessagesRef}></Box>
        {!newMessages?.isLoading
          && (
            <>
              {newMessages?.data?.map((m: any, i: number) => {
                return <MessageBox key={i} message={m.text} time={m.sentAt} isSender={m.createdBy !== MESSAGE_AUTHOR.MANAGED_USER} type={m.type} metadata={m.metadata}></MessageBox>;
              })}

              {newMessages.data?.length > 0 && (
                <Divider
                  my='xs'
                  labelPosition='center'
                  label={
                    <>
                      <Group gap='xs' align='center'>
                        <BerryIcon style={{ width: 12, height: 12, fill: '#6B7280' }} />
                        <Text size='sm' c='gray' style={{ position: 'relative' }}>New Messages</Text>
                      </Group>
                    </>
                  }
                />
              )}
            </>
          )}
        {!isLoading && readMessagesPages?.pages[0].totalPages < 1 && !newMessages?.data?.length
          ? (
            <Group justify='center'>
              <Text>Type something to start a conversation</Text>
            </Group>
          )
          : (
            readMessages?.map((m: any, i: number) => (
              <MessageBox
                key={i}
                message={m.text}
                time={m.sentAt}
                isSender={m.createdBy !== MESSAGE_AUTHOR.MANAGED_USER}
                type={m.type}
                metadata={m.metadata}
              />
            ))
          )}

        {isFetchingNextPage && (
          <Center>
            <Loader size={24} color='gray' />
          </Center>
        )}

        <Box
          ref={messagesTopRef}
          style={{
            height: 240,
            width: '100%',
          }}
        />
      </Box>
      <Group h={'5dvh'}>
        <ChatInput onSend={handleSubmitMessage} authId={authId} isMobile={true} />
      </Group>
    </Stack>
  );
};

export default ChatBox;
