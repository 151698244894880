import React, { createContext, ReactNode, useContext, useState } from 'react';
import Notification from './Notification';

type Notification = {
  message: string;
  type: string;
};

type NotificationContextType = {
  notification: Notification | null;
  showNotification: (message: string, type?: string) => void;
  hideNotification: () => void;
};

const defaultNotificationContext: NotificationContextType = {
  notification: null,
  showNotification: () => {},
  hideNotification: () => {},
};

const NotificationContext = createContext<NotificationContextType>(defaultNotificationContext);

export const useNotification = (): NotificationContextType => useContext(NotificationContext);

type NotificationProviderProps = {
  children: ReactNode;
};

export const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const [notification, setNotification] = useState<Notification | null>(null);

  // const showNotification = (message: string, type: string = 'info') => {
  //   setNotification({ message, type });
  // };
  const hideNotification = () => {
    setNotification(null);
  };

  const showNotification = (message: string, type: string = 'info') => {
    setNotification({ message, type });
    setTimeout(() => {
      hideNotification();
    }, 3000);
  };

  return (
    <NotificationContext.Provider value={{ notification, showNotification, hideNotification }}>
      <Notification />
      {children}
    </NotificationContext.Provider>
  );
};
