import { useGetDeliveryLinks } from '@/services/api/delivery';
import React from 'react';
import { Overview } from '.';
import { OverviewSkeleton } from './OverviewSkeleton';

export function OverviewWrapper({ draft, isLoading }) {
  const { data: links } = useGetDeliveryLinks(draft?.delivery?.id);

  if (isLoading) {
    // todo: add skeleton
    return <></>;
  }

  return <Overview draft={draft} delivery={draft?.delivery} links={links} />;
}
