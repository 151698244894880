import { Box, Divider, Flex, Group, Skeleton, Space, Stack } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
// import { EmailPreviewDesktop } from '@pages/Emails/EmailItem/actionButtons/EmailPreviewDesktop';
import { calcAverage } from '@/lib/utils';
import { ClickedLinks } from './ClickedLinks';
import { OverviewStats } from './OverviewStats';

export const Overview = ({ links, draft, delivery }) => {
  const openRate = calcAverage(delivery?.sendTo, delivery?.open);
  const clickRate = calcAverage(delivery?.sendTo, delivery?.click);

  const { ref: refEmail, height: heightEmail } = useElementSize();
  const { ref: refStats, height: heightStats } = useElementSize();

  const hasEmailBorderRight = true; // heightEmail > heightStats;

  // sx={{ borderRight: hasEmailBorderRight ? '1px solid black' : 'none' }}

  return (
    <Flex gap={0} justify={'center'}>
      {
        /* <Box
        ref={refEmail}
        w={650}
        style={{
          borderRightStyle: hasEmailBorderRight ? 'solid' : 'none',
          borderRightColor: 'black',
          borderRightWidth: '1px',
        }}

        pl={25}
        pr={25}
      >
        <EmailPreviewDesktop draft={draft} showDesktopVersion={true} />
      </Box> */
      }
      {/* TODO - EmailPreviewDesktop outside overview on other tab */}
      <Box ref={refStats} miw={368} style={{ borderLeftStyle: !hasEmailBorderRight ? 'solid' : 'none', borderLeftColor: 'black', borderLeftWidth: '1px' }}>
        <OverviewStats delivery={delivery} clickRate={clickRate} openRate={openRate} />
        {!links
          ? (
            <>
              <Group justify={'center'}>
                <Divider w={304} />
              </Group>
              <Space h={40} />
              <Stack gap={8} align='center'>
                <Skeleton h={20} w={270} />
                <Skeleton h={45} w={50} />
              </Stack>
              <Space h={40} />
              <Stack gap={8} align='center'>
                <Skeleton h={20} w={270} />
                <Skeleton h={45} w={50} />
              </Stack>
              <Space h={40} />
            </>
          )
          : (
            links?.length > 0 && <ClickedLinks links={links} />
          )}
      </Box>
    </Flex>
  );
};

export default Overview;
