import { MailberryLoader } from '@/components/display/loader/MailberryLoader';
import { Box } from '@mantine/core';
import React, { useEffect } from 'react';
// import Chat from '../Chat';
import Overview from './Overview';
// import TourWrapper, { steps } from '@/services/tour';
// import Reports from './Reports';
import { useTour } from '@reactour/tour';

export default function DemoDashboard() {
  const { setIsOpen } = useTour();

  useEffect(() => {
    const tourCompleted = localStorage.getItem('tourCompleted');
    if (!tourCompleted && tourCompleted !== '1') {
      setIsOpen(true);
    }
  }, []);

  return (
    <Box h={'100%'} w={'100%'}>
      <MailberryLoader visible={false} />
      <Overview />
    </Box>
  );
}
