import BerryIcon from '@/assets/icons/BerryIcon';

import useInView from '@/services/hooks/useInView';
import useCheckMobileScreen from '@/services/hooks/useMobileScreen';
import AppState from '@/services/state/AppState';
import { MESSAGE_AUTHOR } from '@/types/models';
import { Loader2 } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import ChatBox from './ChatBox';
import Inbox from './Inbox';

import Wrapper from '@/components/Wrapper';
import { useNavigate, useSearchParams } from 'react-router-dom';

const AdminChat = () => {
  const navigate = useNavigate();
  // get the query params from the url
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    if (!token) {
      navigate('/');
    }
  }, [token]);

  const [currentChat, setCurrentChat] = useState<Record<string, any> | null>(null);
  const [num, setNum] = useState(0);

  const onClickChat = (chat: Record<string, any>) => {
    setNum(num + 1);
    setCurrentChat(chat);
  };

  const onExitChatBox = () => {
    setCurrentChat(null);
  };
  if (!isMobile) {
    return (
      <Wrapper>
        {/* Render Inbox component if currentChat is null */}
        <Inbox onClickChat={onClickChat} />
        {/* Conditional rendering based on currentChat state */}
        {currentChat && <ChatBox currentChat={currentChat} onExit={onExitChatBox} key={currentChat.authId} authId={currentChat.authId} num={num} />}
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        {/* Render Inbox component if currentChat is null */}
        <>
          {!currentChat && <Inbox onClickChat={onClickChat} />}
          {/* Conditional rendering based on currentChat state */}
          {currentChat && <ChatBox currentChat={currentChat} onExit={onExitChatBox} key={currentChat.authId} authId={currentChat.authId} num={num} />}
        </>
        <></>
      </Wrapper>
    );
  }
};

export default AdminChat;
