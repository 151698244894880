import { Box, Group, Stack } from '@mantine/core';
import Image from 'next/image';
import React from 'react';
import styles from './BlackPage.module.css';

export const BlankPage = ({ children }) => {
  return (
    <Box h='100%' w='100%' pos={'absolute'} display='flex'>
      <Group className={styles.rootBox} style={{}} bg='rgba(255, 255, 255, 0.85)'>
        <Stack className={styles.stack} align='center' justify='center'>
          {children}
        </Stack>
      </Group>
    </Box>
  );
};
