import { ChatMessage, MESSAGE_AUTHOR } from '@/types/models';
import { UseInfiniteQueryOptions } from '@tanstack/react-query';
import ReloadTokens, { ReloadTokenType } from '../state/ReloadTokensState';
import { genericAuthRequest, useMailberryInfiniteQuery, useMailberryMutate, useMailberryQuery } from './base';

type SendMessage = {
  createdBy: MESSAGE_AUTHOR;
  createdByIdentity: string;
  text: string;
};
type GetReadMessages = {
  totalPages: number;
  total: number;
  messages: ChatMessage[];
  page: number;
};

export function useSendMessage(onSuccess?: () => void, onError?: () => void) {
  return useMailberryMutate<{}, SendMessage>({
    f: (accessToken, userData) => {
      return genericAuthRequest(accessToken, 'post', `/messages`, userData);
    },
    options: { onSuccess, onError },
    reloadTokenType: ReloadTokenType.Chat,
  });
}

export function useGetReadMessagesInfiniteQuery(limit: number, options?: UseInfiniteQueryOptions) {
  const { reloadTokens } = ReloadTokens.useContainer();
  return useMailberryInfiniteQuery<GetReadMessages>({
    f: (accessToken, pageParam = 1) => {
      return genericAuthRequest(accessToken, 'get', '/messages/read', { limit, page: pageParam });
    },
    queryKey: [reloadTokens[ReloadTokenType.Chat]],
    options: {
      ...options,
      cacheTime: 0,
    },
  });
}

export function useGetReadMessages(limit: number, page: number) {
  const { reloadTokens } = ReloadTokens.useContainer();
  return useMailberryQuery<GetReadMessages>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', '/messages/read', { limit, page });
    },
    queryKey: [page, reloadTokens[ReloadTokenType.Chat]],
    options: { cacheTime: 0 },
  });
}

export function useGetUnreadMessages() {
  const { reloadTokens } = ReloadTokens.useContainer();
  return useMailberryQuery<ChatMessage[]>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/messages/unread`);
    },
    queryKey: [reloadTokens[ReloadTokenType.Chat]],
    options: { refetchInterval: 3000, cacheTime: 0 },
  });
}

export function useGetNewMessages(lastMessageId: string | null) {
  const { reloadTokens } = ReloadTokens.useContainer();
  return useMailberryQuery<ChatMessage[]>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/messages/new`, { lastMessageId });
    },
    queryKey: [reloadTokens[ReloadTokenType.Chat], lastMessageId],
    options: { refetchInterval: 3000, cacheTime: 0, staleTime: 0 },
  });
}

export function useMarkAsRead() {
  return useMailberryMutate<{}, {}>({
    f: (accessToken, userData) => {
      return genericAuthRequest(accessToken, 'post', `/messages/mark-as-read`, userData);
    },
    reloadTokenType: ReloadTokenType.Chat,
  });
}

// export function useGetMessages() {
//   const { reloadTokens } = ReloadTokens.useContainer();
//   return useMailberryQuery<any>({
//     f: accessToken => {
//       return genericAuthRequest(accessToken, 'get', `/messages`);
//     },
//     queryKey: [reloadTokens[ReloadTokenType.Chat]],
//     options: { refetchInterval: 10000 },
//   });
// }

// export function useGetMessagesCount() {
//   const { reloadTokens } = ReloadTokens.useContainer();
//   return useMailberryQuery<any>({
//     f: accessToken => {
//       return genericAuthRequest(accessToken, 'get', `/messages/count`);
//     },
//     queryKey: [reloadTokens[ReloadTokenType.Chat]],
//     options: { refetchInterval: 10000 },
//   });
// }
