// @ts-nocheck
// import { displayErrorNotification } from '@services/notifications';
// import AppState from '@services/state/AppState';
import { MutationOptions, useInfiniteQuery, UseInfiniteQueryOptions, UseInfiniteQueryResult, useMutation, UseMutationResult, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import ReloadTokens, { ReloadTokenType } from '../state/ReloadTokensState';

export type IQueryKey = (string | number | boolean | null | undefined)[];

const hasKey = (o, k) => {
  return Object.keys(o).includes(String(k));
};

const MailBerryBackend = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_HOST + '/managed',
  timeout: 30000, // Backend max timeout is 30 seconds as per AWS Lambda functions
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const RuntimeBackend = axios.create({
  baseURL: process.env.NEXT_PUBLIC_RUNTIME_API_HOST + '/api/0.0.1',
  timeout: 30000, // Backend max timeout is 30 seconds as per AWS Lambda functions
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const genericAuthRequest = (accessToken: string, verb: string, url: string, userData = {}, backend = MailBerryBackend) => {
  if (verb.toLowerCase() === 'get') {
    return (
      backend({
        method: verb,
        url: url,
        headers: { accessToken },
        params: userData,
      })
        // this should not fail ever, if it does, the API is returning 2xx with a bad interface message I believe (@dnuske 2023/04/20)
        .then(data => {
          return data.data.data;
        })
    );
  }
  return (
    backend({
      method: verb,
      url: url,
      headers: { accessToken },
      data: userData,
    })
      // this should not fail ever, if it does, the API is returning 2xx with a bad interface message I believe (@dnuske 2023/04/20)
      .then(data => {
        return data.data.data;
      })
  );
};

export const genericRequest = (verb: string, url: string, userData = {}, backend = MailBerryBackend) => {
  if (verb.toLowerCase() === 'get') {
    return (
      backend({
        method: verb,
        url: url,
        headers: {},
        params: userData,
      })
        // this should not fail ever, if it does, the API is returning 2xx with a bad interface message I believe (@dnuske 2023/04/20)
        .then(data => {
          return data.data.data;
        })
    );
  }
  return (
    backend({
      method: verb,
      url: url,
      headers: {},
      data: userData,
    })
      // this should not fail ever, if it does, the API is returning 2xx with a bad interface message I believe (@dnuske 2023/04/20)
      .then(data => {
        return data.data.data;
      })
  );
};

type TStatusCode = string;

type TCustomQueryError = Record<TStatusCode, (p: AxiosError<{ message: string; }>) => {}>;

export interface IUseMailberryQuery {
  f: (accessToken: string) => AxiosPromise | object;
  queryKey?: IQueryKey;
  options?: UseQueryOptions; // Record<string, string | number>;
  customErrorHandlers?: TCustomQueryError;
}

export interface IUseMailberryInfiniteQuery {
  f: (accessToken: string, pageParam: number) => AxiosPromise | object;
  queryKey?: IQueryKey;
  options?: UseInfiniteQueryOptions;
  customErrorHandlers?: TCustomQueryError;
}

export interface IUseMailberryMutate<T> {
  f: (accessToken: string, userParameter: T) => AxiosPromise;
  options?: MutationOptions<unknown, AxiosError<{ message: string; }>>;
  reloadTokenType?: any; // ReloadTokenType | ReloadTokenType[];
  customErrorHandlers?: TCustomQueryError;
}

export function useMailberryQuery<T>({ f, queryKey, options, customErrorHandlers }: IUseMailberryQuery): UseQueryResult<T> {
  const auth = useAuth();
  const navigate = useNavigate();

  if (!queryKey) {
    queryKey = [];
  }

  if (!options) {
    options = {};
  }

  // TODO: remove when queryKey is sent as array in evey call
  if (!Array.isArray(queryKey)) {
    queryKey = [queryKey];
  }

  const query = useQuery([String(f), ...queryKey], () => f(auth.accessToken), {
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 1 * (60 * 1000), // 1 min
    ...options,
  });

  if (query?.error) {
    const isLogicError = !query?.error?.response?.status;

    if (isLogicError && String(query?.error) !== 'AxiosError: Network Error') {
      // displayErrorNotification(String(query?.error));
      console.log('ERROR1', String(query?.error));
    }

    // this is a special case, we want this to be on top of everything to handle refresh token and sign out
    if (query?.error?.response?.status === 401) {
      auth.signOut();
      // auth.refreshSession();
      // navigate('/check-session');
      return query as UseQueryResult<T>;
    }

    const statusCode = query?.error?.response?.status;
    // if developer is sending custom error handler function for this status code
    if (statusCode && customErrorHandlers && hasKey(customErrorHandlers, statusCode)) {
      customErrorHandlers[String(statusCode)](query?.error);
    } else {
      const message = query?.error?.response?.data?.message || query?.error?.message || '';
      console.log('ERROR2', String(message) || String(query?.error));
      // displayErrorNotification(String(message) || String(query?.error));
    }
  }

  return query as UseQueryResult<T>;
}

export function useMailberryMutate<T, K>({ f, options = {}, reloadTokenType, customErrorHandlers }: IUseMailberryMutate<K>): UseMutationResult<T, AxiosError, K> {
  // const appState = AppState.useContainer();

  const { setReloadToken } = ReloadTokens.useContainer();
  // const navigate = useNavigate();
  const auth = useAuth();

  const _onSuccess = options.onSuccess;
  options.onSuccess = (data, variables, context) => {
    if (reloadTokenType) {
      if (!Array.isArray(reloadTokenType)) {
        reloadTokenType = [reloadTokenType];
      }
      reloadTokenType.forEach(tokenKey => setReloadToken(tokenKey));
      _onSuccess?.(data, variables, context);
    }
  };

  const _onError = options.onError;
  options.onError = (error, variables, context) => {
    const isLogicError = !error?.response?.status;

    if (isLogicError) {
      console.log('ERROR1', String(error));
      //  displayErrorNotification(String(error));
    }

    if (error?.response?.status === 401) {
      auth.signOut();
      // navigate('/check-session');
      // auth.refreshSession();
      return;
    }

    const statusCode = error?.response?.status;
    // if developer is sending custom error handler function for this status code
    if (statusCode && customErrorHandlers && hasKey(customErrorHandlers, String(statusCode))) {
      customErrorHandlers[String(statusCode)](error);
    } else {
      let message = error?.response?.data?.message || error?.response?.data?.data?.message || error?.response?.data?.data?.errorMessage || error?.message || 'Error';
      console.log('ERROR22', String(message) || String(error));
      //  displayErrorNotification(String(message) || String(error));
    }

    _onError?.(error, variables, context);
  };

  options.retry = false;

  const mutation = useMutation(data => f(auth.accessToken, data), { ...options });

  return {
    ...mutation,
  };
}

export function useMailberryInfiniteQuery<T>({ f, queryKey, options, customErrorHandlers }: IUseMailberryInfiniteQuery): UseInfiniteQueryResult<T> {
  const auth = useAuth();
  // const navigate = useNavigate();

  if (!queryKey) {
    queryKey = [];
  }

  if (!options) {
    options = {};
  }

  // TODO: remove when queryKey is sent as array in evey call
  if (!Array.isArray(queryKey)) {
    queryKey = [queryKey];
  }
  // load all contact lists
  const query = useInfiniteQuery(
    [String(f), auth.accessToken, ...queryKey],
    ({ pageParam }) => f(auth.accessToken, pageParam),
    {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 3 * (60 * 1000), // 3 min
      ...options,
    },
  );

  if (query?.error) {
    const isLogicError = !query?.error?.response?.status;

    if (isLogicError && String(query?.error) !== 'AxiosError: Network Error') {
      // displayErrorNotification(String(query?.error));
    }

    // this is a special case, we want this to be on top of everything to handle refresh token and sign out
    if (query?.error?.response?.status === 401) {
      // navigate('/check-session');
      return query as UseInfiniteQueryResult<T>;
    }

    const statusCode = query?.error?.response?.status;
    // if developer is sending custom error handler function for this status code
    if (statusCode && customErrorHandlers && hasKey(customErrorHandlers, statusCode)) {
      customErrorHandlers[String(statusCode)](query?.error);
    } else {
      const message = query?.error?.response?.data?.message || query?.error?.message || '';
      //  displayErrorNotification(String(message) || String(query?.error));
    }
  }

  return query as UseInfiniteQueryResult<T>;
}

export { MailBerryBackend, RuntimeBackend };
