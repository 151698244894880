'use client';
import NonSSRWrapper from '@/components/NonSSRWrapper';
import { NotificationProvider } from '@/components/notifications/NotificationProvider';
import StateProviders from '@/services/state/StateProviders';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import Main from './Main';

import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import { steps } from '@/services/tour';
import { colorsTuple, createTheme, MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { TourProvider } from '@reactour/tour';

const theme = createTheme({
  /** Put your mantine theme override here */
  colors: {
    'brand-green': colorsTuple(Array.from({ length: 10 }, (_, index) => '#7ECB7C')),
    'brand-violet': colorsTuple(Array.from({ length: 10 }, (_, index) => '#D1C9EC')),
    'brand-purple': colorsTuple(Array.from({ length: 10 }, (_, index) => '#7981D0')),
    'brand-deep-purple': colorsTuple(Array.from({ length: 10 }, (_, index) => '#24335F')),
    'brand-black': colorsTuple(Array.from({ length: 10 }, (_, index) => '#152228')),
  },
});
const queryClient = new QueryClient();

export default function Page() {
  return (
    <NonSSRWrapper>
      {/*<Script id={'clarity'}>*/}
      {/*  {`(function (c, l, a, r, i, t, y){*/}
      {/*  c[a] = c[a] || function () {*/}
      {/*    (c[a].q = c[a].q || []).push(arguments)*/}
      {/*  };*/}
      {/*    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;*/}
      {/*    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);*/}
      {/*  })*/}
      {/*  (window, document, "clarity", "script", "nlubz9g4m1"*/}
      {/*  )*/}
      {/*  ;`}*/}
      {/*</Script>*/}
      <BrowserRouter>
        <StateProviders>
          <QueryClientProvider client={queryClient}>
            <MantineProvider theme={theme}>
              <TourProvider inViewThreshold={80} showPrevNextButtons={false} showBadge={false} disableFocusLock steps={steps} showDots={false} disableDotsNavigation disableKeyboardNavigation>
                <ModalsProvider>
                  <NotificationProvider>
                    <Notifications position='top-right' limit={5} />
                    <Main />
                  </NotificationProvider>
                </ModalsProvider>
              </TourProvider>
            </MantineProvider>
          </QueryClientProvider>
        </StateProviders>
      </BrowserRouter>
    </NonSSRWrapper>
  );
}
