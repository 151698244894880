import { MBStatsItem } from '@/mailberry/MBStatsItem';
import MBClicked from '@assets/icons/MBClicked';
import MBOpen from '@assets/icons/MBOpen';
import MBRecipients from '@assets/icons/MBRecipients';
import MBSentTo from '@assets/icons/MBSentTo';
import MBUnsubscriber from '@assets/icons/MBUnsubscriber';
import { Group, Space } from '@mantine/core';

export function OverviewStats({ delivery, clickRate, openRate }) {
  return (
    <>
      <Space h={20} />
      <Group justify='center'>
        <MBStatsItem icon={<MBRecipients size={24} />} title={'Recipients'} value={delivery?.sendTo} color={'mbneutrals.9'} />
      </Group>
      <Space h={10} />
      <Group justify='center' gap={48}>
        {/* {delivery?.alreadySent && <MBStatsItem icon={<MBSentTo size={24} />} title={'Sent to'} value={delivery?.alreadySent} color={'black'} />} */}
        <MBStatsItem icon={<MBOpen size={24} />} title={'Open Rate'} value={`${openRate}%`} color={'black'} />
        <MBStatsItem icon={<MBClicked size={24} />} title={'Click Rate'} value={`${clickRate}%`} color={'black'} />
      </Group>
      <Space h={10} />
      <Group justify='center'>
        <MBStatsItem icon={<MBUnsubscriber size={24} />} title={'Unsubscribers'} value={delivery?.unsubscribe} color={'mbneutrals.9'} />
      </Group>
      <Space h={20} />
    </>
  );
}
