import dynamic from 'next/dynamic';
import React from 'react';

const NonSSRWrapper = ({ children }: {
  children: React.ReactNode;
}) => <React.Fragment>{children}</React.Fragment>;

export default dynamic(() => Promise.resolve(NonSSRWrapper), {
  ssr: false,
});
