import useCheckMobileScreen from '@/services/hooks/useMobileScreen';
import { Box, Group, Stack } from '@mantine/core';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Wrapper = ({ children }: { children: React.ReactNode[]; }) => {
  const isMobile = useCheckMobileScreen();

  if (!children) return;

  const leftChildren = children[0];
  const rightChildren = children[1];

  return (
    <Group
      className={'flex justify-center  md:gap-8 lg:gap-16  md:p-4 w-full md:w-auto  md:h-[auto] '}
      justify='center'
      w={isMobile ? '100%' : 'auto'}
      gap={isMobile ? 0 : 8}
      h={isMobile ? '100%' : '100%'}
    >
      <Box
        key='left' // className='md:shadow-[0_0_24px_rgba(0,0,0,0.1)] w-full md:rounded-lg md:w-[400px] overflow-y-auto  '
        h={isMobile ? '100%' : '100%'}
        w={isMobile ? '100%' : '400px'}
        style={{
          overflowY: 'auto',
          boxShadow: '0 0 24px rgba(0,0,0,0.1)',
          borderRadius: '8px',
        }}
      >
        {leftChildren}
      </Box>

      {(rightChildren && !isMobile) && (
        <Box
          key={'rigth'} // className='md:shadow-[0_0_24px_rgba(0,0,0,0.1)] w-full md:rounded-lg  md:w-[400px] overflow-y-auto'
          h={isMobile ? '100%' : '100%'}
          w={isMobile ? '100%' : '400px'}
          style={{
            overflowY: 'auto',
            boxShadow: '0 0 24px rgba(0,0,0,0.1)',
            borderRadius: '8px',
          }}
        >
          {rightChildren}
        </Box>
      )}

      {
        /* <div
      className={'flex flex-col h-full   w-full gap-2 overflow-y-auto  md:shadow-[0_0_24px_rgba(0,0,0,0.1)] md:rounded-lg bg-white  md:max-h-[calc(90%)] '
        + `${(isMobile || showDesktopVersion) ? 'md:w-[596px]' : ' md:w-[370px] scale-[0.8] origin-top'}`}
    >
      <div className='flex flex-col  relative overflow-y-auto'>
        <div className={`${(!isMobile && showDesktopVersion) ? 'editor-preview-desktop' : 'editor-preview-mobile'}  `}>
          <HTMLRendered html={htmlParsed!} />
          <EmailPreviewFooter businessMailAddress={emailReview?.businessAddress!} />
        </div>
      </div>

      {emailReview?.status === REVIEW_STATUS.READY_FOR_REVIEW
        ? (
          <div className='flex h-26 gap-2 p-4 border-t border-gray-200/40'>
            <Button disabled={updateEmailReview.isLoading} type='submit' className='bg-orange-700 w-full h-20 text-lg md:h-10 md:text-md' onClick={handleRequestChangues}>
              {updateEmailReview.isLoading && <Loader2 className='mr-2 h-4 w-4 animate-spin' />}
              Submit changes
            </Button>
            <Button
              disabled={localCommentsCounter > 0 || updateEmailReview.isLoading}
              type='submit'
              className={'w-full h-20 text-lg md:h-10  md:text-md ' + `${localCommentsCounter > 0 ? 'bg-gray-500 cursor-not-allowed' : 'bg-green-700'}`}
              onClick={handleApproveEmail}
            >
              {updateEmailReview.isLoading && <Loader2 className='mr-2 h-4 w-4 animate-spin' />}

              Approve
            </Button>
          </div>
        )
        : (
          <div className='h-40 md:h-20 bg-indigo-400 flex  items-center justify-center'>
            <h2 className='text-center  text-xl font-bold text-white  '>{emailReview.status.charAt(0).toUpperCase() + emailReview.status.slice(1).toLocaleLowerCase().replaceAll('_', ' ')}</h2>
          </div>
        )}
    </div> */
      }
    </Group>
    //   {isMobile
    //     ? (
    //       <>
    //         {showCommentList && <CommentList onClose={resetSelection} comments={selectedNodeComments} />}
    //         {!showCommentList && (
    //           <CommentInput
    //             onClose={resetSelection}
    //             handleAddComment={handleAddComment}
    //             isVisible={showCommentInput && emailReview?.status === REVIEW_STATUS.READY_FOR_REVIEW}
    //           >
    //           </CommentInput>
    //         )}
    //       </>
    //     )
    //     : (
    //       <div className='hidden md:flex md:flex-col  md:shadow-[0_0_24px_rgba(0,0,0,0.1)] md:rounded-lg md:w-96 md:max-h-[calc(90%)]'>
    //         <CommentList onClose={resetSelection} comments={selectedNodeComments} />
    //         <CommentInput onClose={resetSelection} handleAddComment={handleAddComment} isVisible={showCommentInput && emailReview?.status === REVIEW_STATUS.READY_FOR_REVIEW}></CommentInput>
    //       </div>
    //     )}
  );
};

export default Wrapper;
