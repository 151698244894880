import Dexie, { Table } from 'dexie';

export interface Session {
  accessToken: string;
  refreshToken: string;
  email: string;
  updatedAt: string;
  chat: Record<any, any>;
}

class SessionDB extends Dexie {
  sessions!: Table<Session, string>;

  constructor() {
    super('SessionDB');
    this.version(1).stores({
      sessions: 'email',
    });
  }
}

const db = new SessionDB();

const deleteAllSessions = async () => {
  await db.sessions.clear();
};

const getSession = async () => {
  const session = await db.sessions.limit(1).toArray();
  return session[0];
};

const insertSession = async (session: Session) => {
  await db.sessions.add(session);
};

const updateSession = async (email: string, session: Partial<Omit<Session, 'email'>>) => {
  await db.sessions.update(email, session);
};

export default {
  getSession,
  insertSession,
  updateSession,
  deleteAllSessions,
};
