import { Divider, Group, Space, Text } from '@mantine/core';
import React from 'react';
import { ClickedLinkItem } from './ClickedLinkItem';

export function ClickedLinks({ links }) {
  return (
    <>
      <Group justify={'center'}>
        <Divider w={304} />
      </Group>
      <Space h={40} />
      <Group justify='center'>
        <Text fs={'16px'} fw={700} lh={'24px'} c={'mbneutrals.9'}>
          No. of Subscribers that clicked a link
        </Text>
      </Group>
      <Space h={40} />

      {links.map((link, index) => {
        return <ClickedLinkItem key={index} link={link} />;
      })}
      <Space h={40} />
    </>
  );
}
