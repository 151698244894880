import { useAuth } from '@/services/hooks/useAuth';
import AppState from '@/services/state/AppState';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ProtectedDemoRoute = ({ children }: { children: React.ReactNode; }) => {
  const appState = AppState.useContainer();
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth && !auth.isAuthenticated && !auth.isLoading && !appState.signupAndLogin.isLoadingToken) {
      auth.signOut(() => {
        console.log('logout');
        // appState.resetAll();
        // navigate('/login');
      });
    }
  }, [auth?.isAuthenticated, auth?.isLoading, appState.signupAndLogin.isLoadingToken]);

  if (auth?.isAuthenticated) {
    return (
      <>
        {children}
      </>
    );
  }

  return null;
};

export default ProtectedDemoRoute;
