import { Settings } from '@/components/screens/Settings';
import TabsMenu from '@components/screens/TabsMenu';
import DemoTabsMenu from '@components/screens/TabsMenu/DemoTabsMenu';
import { ActionIcon, Box, Group, Image } from '@mantine/core';
import { ArrowLeftIcon } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import AppState from '../../../services/state/AppState';
import classes from './Navbar.module.scss';

interface INavbar {
  showBackArrow: boolean;
  isMobileView: boolean;
  isDemo?: boolean; // TODO: set a global state isDemo=true and render components accordingly
}

export const Navbar = ({ showBackArrow, isMobileView, isDemo = false }: INavbar) => {
  const navigate = useNavigate();
  const appState = AppState.useContainer();

  const onBackClick = () => {
    navigate(-1);
  };

  if (isMobileView) {
    return (
      <Group
        h={'rem(40px)'}
        w='100%'
        justify='space-between'
        align='center'
        className={classes.navbar}
      >
        {showBackArrow
          ? (
            <ActionIcon onClick={onBackClick} color='#ffffff' style={{ cursor: 'pointer' }}>
              <ArrowLeftIcon color='#111827' style={{ width: 24, height: 24 }} />
            </ActionIcon>
          )
          : <Box w={28} style={{ flexGrow: 1 }}></Box>}
        <Group>
          <Link to={'/'} style={{ alignSelf: 'flex-start' }}>
            <Image src='/assets/mailberry-logo-form.svg' alt='Mailberry logo' h={'30px'} w={'auto'} my={'4px'} />
          </Link>
          {/* <Button onClick={() => navigate('/admin-chat')}>Admin chat</Button> */}
        </Group>
        {appState.signupAndLogin.accessToken && <Settings />}
      </Group>
    );
  } else {
    return (
      <Group
        h={'rem(70px)'}
        w='100%'
        justify='space-between'
        align='center'
        className={classes.navbar}
      >
        <Group style={{ flex: 1 }}>
          {showBackArrow
            ? (
              <ActionIcon onClick={onBackClick} color='#ffffff' style={{ cursor: 'pointer' }}>
                <ArrowLeftIcon color='#111827' style={{ width: 24, height: 24 }} />
              </ActionIcon>
            )
            : null}
          <Link to={'/'} style={{ alignSelf: 'flex-start', height: 'rem(50px)' }}>
            <Image src='/assets/mailberry-logo-form.svg' alt='Mailberry logo' h={'100%'} w={'auto'} />
          </Link>
        </Group>
        {isDemo ? <DemoTabsMenu /> : appState.signupAndLogin.accessToken && <TabsMenu />}
        {/* <Button onClick={() => navigate('/admin-chat')}>Admin chat</Button> */}
        {appState.signupAndLogin.accessToken && <Settings />}
      </Group>
    );
  }
};
