import { Paper } from '@mantine/core';

interface MailberryPaperProps {
  children: React.ReactNode;
  sx?: any;
  onClick?: () => void;
  p?: string | number;
  m?: string | number;
}

export default function MailberryPaper({ children, sx, onClick = () => {}, p = 'xl', m = 'xl' }: MailberryPaperProps) {
  return (
    <Paper
      radius='lg'
      p={p}
      m={m}
      style={t => ({
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'gray',
        borderRadius: 8,
        ...sx,
      })}
      onClick={onClick}
    >
      {children}
    </Paper>
  );
}
