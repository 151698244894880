import { Avatar, Group, Skeleton, Stack, Text, Title } from '@mantine/core';
import React from 'react';

export default function AccountInfo({ account, isLoading }) {
  if (isLoading || !account) {
    return (
      <Group align='center' gap='xl' justify='start' m={'lg'}>
        <Skeleton circle height={130} width={130} />
        <Stack gap={0}>
          <Skeleton height={22} width={200} mb='xs' />
          <Skeleton height={30} width={120} />
        </Stack>
      </Group>
    );
  }
  return (
    <Group align='center' gap='xl' justify='start' wrap='nowrap' m={'lg'}>
      <Avatar
        src={account.logoUrl ? account.logoUrl : ''}
        alt='Mailberry Logo'
        h={'130px'}
        w={'auto'}
        style={{ borderRadius: '50%', aspectRatio: '1/1' }}
      />
      <Stack gap={0}>
        <Title order={3} fw={400}>
          {account.brandName}
        </Title>
        <Title order={2}>{account.activeSubscribers}</Title>
        <Text size={'1em'}>Active subscribers</Text>
      </Stack>
    </Group>
  );
}
