import { Capacitor } from '@capacitor/core';
import CapacitorStorage from './CapacitorAPI';
import IndexedDb, { Session } from './indexedDB';

export const getItem = async () => {
  if (Capacitor.isNativePlatform()) {
    // needed a Promise when call a non dexie API
    // https://dexie.org/docs/dexie-react-hooks/useLiveQuery()#calling-non-dexie-apis-from-querier
    return Promise.resolve(
      CapacitorStorage.getSession().then(session => session),
    );
  }
  return IndexedDb.getSession();
};

export const saveItem = async (session: Session) => {
  if (Capacitor.isNativePlatform()) {
    console.log('CAPACITOR-LOCALSTORAGE', session.accessToken);
    return CapacitorStorage.insertSession(session);
  }
  console.log('LOCALSTORAGE', session.accessToken);
  localStorage.setItem('email', session.email);
  localStorage.setItem('accessToken', session.accessToken);

  const currSession = await IndexedDb.getSession();
  if (currSession?.email === session?.email) {
    return IndexedDb.updateSession(session.email, session);
  }
  return IndexedDb.insertSession(session);
};

export const updateItem = async (email: string, session: Partial<Omit<Session, 'email'>>) => {
  if (Capacitor.isNativePlatform()) {
    return CapacitorStorage.updateSession(session);
  }
  await IndexedDb.updateSession(email, session);
};

export const deleteAll = async () => {
  if (Capacitor.isNativePlatform()) {
    return CapacitorStorage.deleteAll();
  }
  await IndexedDb.deleteAllSessions();
};

export default {
  getItem,
  saveItem,
  updateItem,
  deleteAll,
};
