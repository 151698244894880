import { Button } from '@mantine/core';
import { StepType } from '@reactour/tour';
import React from 'react';

export const steps: StepType[] = [
  {
    selector: '.tour',
    content: ({ setCurrentStep }) => (
      <div style={{ textAlign: 'center', borderRadius: '12px' }}>
        <p>Let's take a tour!</p>
        <Button mx={'auto'} onClick={() => setCurrentStep(1)}>Start</Button>
      </div>
    ),
    stepInteraction: false,
  },
  {
    selector: '.first-step',
    content: ({ setCurrentStep }) => (
      <div style={{ textAlign: 'center', borderRadius: '12px' }}>
        <p>Here you can see the email campaigns our team has created for you. Click on "view"</p>
      </div>
    ),
  },
  {
    selector: '.third-step',
    content: ({ setCurrentStep }) => (
      <div style={{ textAlign: 'center', borderRadius: '12px' }}>
        <p>Approve</p>
      </div>
    ),
  },
  {
    selector: '.tour',
    content: ({ setCurrentStep }) => (
      <div style={{ textAlign: 'center', borderRadius: '12px' }}>
        <p>Check your inbox</p>
        <Button mx={'auto'} onClick={() => setCurrentStep(999)}>Close</Button>
      </div>
    ),
    stepInteraction: false,
    styles: { close: () => ({ display: 'none' }) },
    actionAfter: () => localStorage.setItem('tourCompleted', '1'),
  },
];
