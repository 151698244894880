export enum SUBSCRIBER_STATUS {
  ACTIVE = 'ACTIVE',
  BOUNCED = 'BOUNCED',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
  SPAM = 'SPAM',
}

export enum SystemMessageStatus {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export enum DELIVERY_SUBSCRIBER_FILTERS {
  OPENED = 'OPENED',
  CLICKED = 'CLICKED',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
}

export enum EMAILS_VIEW_TABS {
  ALL = 'ALL',
  DRAFTS = 'DRAFTS',
  SCHEDULED = 'SCHEDULED',
  SENT = 'SENT',
}

export enum REPORT_VIEW_TABS {
  OVERVIEW = 'OVERVIEW',
  PREVIEW = 'PREVIEW',
  RECIPIENTS = 'RECIPIENTS',
  OPENS = 'OPENS',
  CLICKS = 'CLICKS',
  UNSUBSCRIBES = 'UNSUBSCRIBES',
}

export enum AUDIENCE_VIEW_TABS {
  SUBSCRIBERS = 'SUBSCRIBERS',
  SEGMENTS = 'SEGMENTS',
  FORMS = 'FORMS',
  FILES = 'FILES',
  MANUALLY = 'MANUALLY',
}

export enum SETTINGS_VIEW_TABS {
  PROFILE = 'PROFILE',
  BRAND = 'BRAND',
  EMAILS = 'EMAILS',
  BILLING = 'BILLING',
}

export type IPaginationRequest = {
  limit: number;
  offset: number;
};

export type IQueryKey = (string | number | boolean | null | undefined)[];

export enum NODE_ENVS {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEV = 'development',
}

export enum ASSETS_FOLDER {
  CSV = 'CSV',
  IMAGE = 'IMAGE',
  LOGO = 'LOGO',
  SUPPRESSED_LIST = 'SUPPRESSED_LIST',
  EDITOR_FILE = 'EDITOR_FILE',
}
