import AppState from './AppState';
import ReloadTokensState from './ReloadTokensState';

export default function StateProviders({ children }: { children: React.ReactNode; }) {
  return (
    <AppState.Provider>
      <ReloadTokensState.Provider>
        {children}
      </ReloadTokensState.Provider>
    </AppState.Provider>
  );
}
