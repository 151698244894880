import { MailberryLoader } from '@/components/display/loader/MailberryLoader';
import { displayErrorNotification } from '@/components/display/notifications';
import { postAuthOtp } from '@/services/api/auth';
import { useAuth } from '@/services/hooks/useAuth';
import { Anchor, Box, Button, Group, Image, PinInput, Stack, Text, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { AccountType } from '@prisma/client';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import styles from './SignIn.module.css';

type FormValues = {
  email: string;
  code: string;
};

export default function SignIn() {
  const signUpMutation = useMutation({ mutationFn: postAuthOtp });
  const auth = useAuth();
  const navigate = useNavigate();

  const [formStep, setFormStep] = useState(1);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const emailSchema = z.object({
    email: z.string().email('Invalid email').max(200),
  });
  const codeSchema = z.object({
    code: z.string().min(6, 'Code must be at least 6 characters') || z.string().max(200, 'Password must be at most 200 characters'),
  });
  const formSchema = formStep === 1 ? emailSchema : codeSchema;

  const form = useForm<FormValues>({
    validate: zodResolver(formSchema),
    initialValues: { email: '', code: '' },
  });

  const handleOnSubmit = async (values: FormValues) => {
    if (formStep === 1) {
      setLoading(true);
      try {
        const textInput = values.email.trim().toLowerCase();
        const { data } = await signUpMutation.mutateAsync({ email: textInput, accountType: AccountType.Customer });

        // Bypass OTP login for development and testing
        if (textInput === 'store@test.com' || process.env.NEXT_PUBLIC_NODE_ENV === 'development') {
          const { accessToken } = data?.data;
          auth.signIn(textInput, accessToken, () => {
            reset();
            navigate('/');
          });
        } else {
          const { message, error } = data?.data;
          if (message && message === 'OK') {
            setFormStep(2);
            setEmail(textInput);
            form.reset();
          } else if (error) {
            displayErrorNotification('Error', message, 5000);
          }
        }
      } catch (e: any) {
        let message;
        if (e instanceof AxiosError) {
          message = e.response?.data.message;
        } else {
          message = e.message;
        }
        displayErrorNotification("Couldn't sign up", message, 5000);
      } finally {
        setLoading(false);
      }
    } else {
      const code = values.code.trim();
      auth.signIn(email, code, () => {
        reset();
        navigate('/');
      });
    }
  };

  const reset = () => {
    setFormStep(1);
    setEmail('');
    form.reset();
  };

  const handleSingUpDemoClick = () => {
    navigate('/signup-demo');
  };

  return (
    <Box>
      <MailberryLoader visible={auth.isLoading} />
      <Group justify='center' className={styles.rootBox}>
        <Stack align='center' justify='center'>
          <Stack mb='xl'>
            <Image src='/assets/mailberry-logo-form.svg' alt='Mailberry logo' width={180} height={40} />
          </Stack>
          {formStep === 1
            ? (
              <form onFocus={() => auth.errorMessage && auth.clearErrorMessage()} onSubmit={form.onSubmit(handleOnSubmit)}>
                <Stack align='center' justify='center' gap={1}>
                  <TextInput
                    size='md'
                    mb={20}
                    w={365}
                    label='Enter your email address'
                    placeholder='email@example.com'
                    required
                    {...form.getInputProps('email')}
                  />
                  <Button fullWidth type='submit' loading={loading} size='lg'>
                    Get one time password
                  </Button>
                  <Text>or</Text>
                  <Button onClick={handleSingUpDemoClick} fullWidth type='button' size='lg'>
                    Show me a demo
                  </Button>
                </Stack>
              </form>
            )
            : (
              <form onFocus={() => auth.errorMessage && auth.clearErrorMessage()} onSubmit={form.onSubmit(handleOnSubmit)}>
                <Stack align='center' justify='center' gap={2}>
                  <Text>
                    Check your inbox at:<Text inherit span fw={700}>{` "${email}"`}</Text>
                  </Text>
                  <PinInput
                    placeholder='-'
                    length={6}
                    size='md'
                    {...form.getInputProps('code')}
                  />
                  <Group mt='md' justify='center' w='365px'>
                    <Button fullWidth type='submit' size='lg'>
                      Confirm
                    </Button>
                    <Anchor size='lg' onClick={reset}>
                      Back
                    </Anchor>
                    <Text fz={12} mb={20} c='dark'>
                      By authenticating, you agree to Mailberry's{' '}
                      <a href='https://mailberry.ai/terms/' target={'_blank'} rel={'noreferrer'} className={styles.link}>
                        Terms of Service
                      </a>{' '}
                      and{' '}
                      <a href='https://mailberry.ai/privacy/' target={'_blank'} rel={'noreferrer'} className={styles.link}>
                        Privacy Policy
                      </a>
                    </Text>
                  </Group>
                </Stack>
              </form>
            )}
        </Stack>
      </Group>
    </Box>
  );
}
