interface SVGIconProps {
  style?: React.CSSProperties;
}

export default function BerryIcon(props: SVGIconProps) {
  return (
    <svg {...props} width='17' height='33' viewBox='0 0 17 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.39691 32.8821C13.0344 32.8821 16.7938 29.1226 16.7938 24.4852C16.7938 19.8476 13.0344 16.0881 8.39691 16.0881C3.75943 16.0881 0 19.8476 0 24.4852C0 29.1226 3.75943 32.8821 8.39691 32.8821Z'
        fill='#0F1E24'
      />
      <path d='M0.353516 15.7341C0.353516 15.7341 0.530292 0.531309 16.2635 0.000976562C16.2635 0.000976562 15.9099 14.4967 0.353516 15.7341Z' fill='#7ECB7C' />
    </svg>
  );
}
