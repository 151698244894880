import { Stack, Text } from '@mantine/core';
import React from 'react';

interface MBStatsItemProps {
  icon?: React.ReactNode;
  title: string;
  value: string;
  color: string;
}
export function MBStatsItem({ icon, title, value, color }: MBStatsItemProps) {
  return (
    <Stack gap={2} align={'center'}>
      {icon && icon}
      <Text size={'xl'} fw={400} lh={'24px'} c={color || 'mbneutrals.7'}>
        {title}
      </Text>
      <Text size={'2em'} fw={700} lh={'40px'} c={color}>
        {value}
      </Text>
    </Stack>
  );
}
