import { useGetAccount } from '@/services/api/account';
import { Box, Button, ScrollArea, Stack } from '@mantine/core';
import { useTour } from '@reactour/tour';
import React from 'react';
import AccountInfo from './AccountInfo';
import OverallStats from './OverallStats';
import ReviewList from './ReviewList';

export default function Overview() {
  // const { data: account, isLoading: isLoadingUser } = useGetAccount();

  const isLoadingUser = false;

  const account = {
    userId: 'customer.id',
    firstName: 'demo',
    lastName: 'demo',
    // timeZone: 'customer.timeZone',
    email: 'lucasdemo@gmail.com',
    // customerId: 'customer.id',
    brandName: 'ACME',
    // brandAbout: '',
    // systemMessage: [],
    businessAddress: '',
    activeSubscribers: 1230099,
    averageOpenRate: 50,
    // status: 'customer.status',
    //  brandColors: 'customer.brandColors',
    campaingsSent: 578,
    // premiumDetails: {
    //   subscriptionId: 'customer.subscriptionId',
    //   premiumStart: 'customer.premiumStart,',
    //   premiumEnd:' customer.premiumEnd',
    //   cancelAtEnd: 'customer.cancelAtEnd',
    //   paidPackage: 'customer.paidPackage',
    //   intervalPremium: 'customer.intervalPremium',
    // },
  };
  const { setIsOpen } = useTour();

  return (
    <>
      <AccountInfo account={account} isLoading={isLoadingUser} />
      <OverallStats account={account} isLoading={isLoadingUser} />
      <ReviewList />
    </>
  );
}
