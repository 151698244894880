import React, { RefObject, useEffect, useState } from 'react';

// Should we use Framer Motion instead of this?
// https://www.framer.com/motion/use-in-view/
export default function useInView(ref: RefObject<HTMLElement>, margin?: React.CSSProperties['margin']) {
  const [isInView, setInView] = useState(false);

  const onEnter = () => {
    setInView(true);
    return () => setInView(false);
  };

  const options = {
    margin,
    amount: 0.5,
  };

  const activeIntersections = new WeakMap();

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const onIntersectionChange = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        const onEnd = activeIntersections.get(entry.target);

        if (entry.isIntersecting === Boolean(onEnd)) {
          return;
        }
        if (entry.isIntersecting) {
          const newOnEnd = onEnter();
          if (typeof newOnEnd === 'function') {
            activeIntersections.set(entry.target, newOnEnd);
          } else {
            observer.unobserve(entry.target);
          }
        } else if (onEnd) {
          onEnd();
          activeIntersections.delete(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(onIntersectionChange, {
      rootMargin: options.margin as string,
      threshold: options.amount,
    });

    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [ref, margin]);

  return isInView;
}
