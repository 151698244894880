import { Web } from '@/sharedTypes';
import { ChatMessage, MESSAGE_AUTHOR } from '@/types/models';
import { UseInfiniteQueryOptions } from '@tanstack/react-query';
import ReloadTokens, { ReloadTokenType } from '../state/ReloadTokensState';
import { genericAuthRequest, useMailberryMutate, useMailberryQuery } from './base';

export function useGetAccount() {
  return useMailberryQuery<Web.Response.User>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/user/account`);
    },
    // queryKey: [reloadTokens[ReloadTokenType.Chat]],
    options: { refetchInterval: 10000, cacheTime: 10000 },
  });
}

export function useDeletionAccount(onSuccess?: () => void, onError?: () => void) {
  return useMailberryMutate<any, {}>({
    f: (accessToken, userData) => {
      return genericAuthRequest(accessToken, 'get', `/user/account/delete`, userData);
    },
  });
}
