import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { FORM_POPUP_OPTIONS, FORMAT } from './common';
dayjs.extend(utc);
dayjs.extend(timezone);
export var EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/;
export function formatDateToUserTimezone(date, timezone) {
    if (date && timezone)
        return dayjs(date).tz(timezone).format('M/DD/YYYY h:mma');
    return '';
}
// This code is displayed on the last step of the form builder in the frontend
export var generateCodeSnippet = function (form, format, showSignature, params, popupOption) {
    var fields = form.fields, style = form.style, content = form.content;
    var header = content.header, description = content.description, thanksMessage = content.thanksMessage, button = content.button;
    var API_URL = params.API_URL, FORMS_LIB_URL = params.FORMS_LIB_URL;
    var mainStyle = style.main, headStyle = style.header, descriptionThanksMessageAndSignStyle = style.descriptionThanksMessageAndSign, labelStyle = style.label, btnStyle = style.button;
    var fieldsData = fields.filter(function (field) { return field.visible; }).map(function (_a) {
        var type = _a.type, label = _a.label, required = _a.required;
        return ({
            type: type,
            label: label,
            required: required,
        });
    });
    var fieldsJSON = JSON.stringify(fieldsData);
    var styleJSON = JSON.stringify({
        headStyle: headStyle,
        labelStyle: labelStyle,
        btnStyle: btnStyle,
        mainStyle: mainStyle,
        descriptionThanksMessageAndSignStyle: descriptionThanksMessageAndSignStyle,
    });
    var textJSON = JSON.stringify({ header: header, description: description, thanksMessage: thanksMessage, button: button });
    var trackAndSubmitUrl = "".concat(API_URL, "/public/").concat(form.id);
    var scriptSrc = FORMS_LIB_URL;
    // const initFunction = `!(function(_window, _document, a, b, c, d, e, f) {
    //   _window.mailberryForms.init(_window, _document, a, b, c, d, e, f);
    //   //comment this out  and replace "mystyle.css" with your stylessheet location if you want load your custom styles
    //   //_window.mailberryForms.addStylesSheet(_document,"mystyle.css")
    // }(window, document, "${form.id}",${fieldsJSON},${textJSON},"${trackAndSubmitUrl}",${styleJSON},${showSignature}))`;
    //
    var initFunction = "!(function(m, a, i, l, b, e, rr, y) {\n    m.mailberryForms.init(m, a, i, l, b, e, rr, y);\n    // comment this out if you don't want Mailberry default styles\n    m.mailberryForms.addStylesSheet(a, m.mailberryForms.createMBStyleSheet(i, rr))\n  }(window, document, \"".concat(form.id, "\",").concat(fieldsJSON, ",").concat(textJSON, ",\"").concat(trackAndSubmitUrl, "\",").concat(styleJSON, ",").concat(showSignature, "))");
    if (format === FORMAT.popup) {
        // codePopup has the same function, but it accepts one argument more
        // this argument is the last one and has "immediately" as value by default
        var showPopupAt = popupOption !== null && popupOption !== void 0 ? popupOption : FORM_POPUP_OPTIONS['immediately'];
        return "<div data-mailberry-form-id=\"".concat(form.id, "\" data-mailberry-format=\"").concat(format, "\" data-mailberry-popup-option=\"").concat(showPopupAt, "\">\n<script src=\"").concat(scriptSrc, "\"></script>\n<script>").concat(initFunction, "</script>\n</div>");
    }
    if (format === FORMAT.page) {
        return "<!DOCTYPE html>\n<html lang=\"en\">\n<head>\n  <meta charset=\"UTF-8\">\n  <meta http-equiv=\"X-UA-Compatible\" content=\"IE=edge\">\n  <meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\">\n  <title>Document</title>\n</head>\n<body style=\"margin: 0; padding: 0;\">\n  <div data-mailberry-form-id=\"".concat(form.id, "\" data-mailberry-format=\"").concat(format, "\"></div>\n  <script src=\"").concat(scriptSrc, "\"></script>\n  <script>").concat(initFunction, "</script>\n</body>\n</html>");
    }
    else {
        return "<div data-mailberry-form-id=\"".concat(form.id, "\" data-mailberry-format=\"").concat(format, "\">\n<script src=\"").concat(scriptSrc, "\"></script>\n<script>").concat(initFunction, "</script>\n</div>");
    }
};
export var generateCodeReact = function (form, format, showSignature, popupOption) {
    var fields = form.fields, content = form.content;
    var fieldsData = fields.filter(function (field) { return field.visible; }).map(function (_a) {
        var type = _a.type, label = _a.label, required = _a.required;
        return ({
            type: type,
            label: label,
            required: required,
        });
    });
    var inputFields = fieldsData.map(function (field) {
        var inputType = field.type;
        var requiredString = field.required ? 'required' : '';
        if (inputType === 'email') {
            return "<MaillberyForm.EmailInput label='".concat(field.label, "' ").concat(requiredString, " />");
        }
        if (inputType === 'date') {
            return "<MailberrnpmyForm.DateInput label='".concat(field.label, "' ").concat(requiredString, " />");
        }
        if (inputType === 'checkbox') {
            return "<MailberryForm.CheckboxInput label='".concat(field.label, "' ").concat(requiredString, " />");
        }
        return "<MailberryForm.TextInput label='".concat(field.label, "' ").concat(requiredString, " />");
    }).join('\n    ');
    var signature = showSignature ? '' : 'signature={false}';
    if (format === FORMAT.popup) {
        var showPopupAt = popupOption !== null && popupOption !== void 0 ? popupOption : FORM_POPUP_OPTIONS['immediately'];
        var showAt = {
            type: 'TIME',
            value: 0,
        };
        if (showPopupAt === FORM_POPUP_OPTIONS['immediately'])
            showAt.value;
        if (showPopupAt === FORM_POPUP_OPTIONS['after-10-seconds'])
            showAt.value = 10;
        if (showPopupAt === FORM_POPUP_OPTIONS['after-30-seconds'])
            showAt.value = 30;
        if (showPopupAt === FORM_POPUP_OPTIONS['at-30-percent-of-pageview']) {
            showAt = { type: 'SCROLL', value: 0.3 };
        }
        return "<MailberryForm formId='".concat(form.id, "' formContainerStyles={{ background: 'aliceblue }} thanksMessage='").concat(content.thanksMessage, "' format='").concat(format, "' ").concat(signature, " showAt='").concat(JSON.stringify(showAt), "'>\n    <MailberryForm.Description>\n      <h2 style={{ textAlign: 'center }}>").concat(content.header, "</h2>\n      ").concat(!!content.description && "<p>".concat(content.description, "</p>"), "\n      <hr />\n    </MailberryForm.Description>\n    <MailberryForm.FieldError />\n    ").concat(inputFields, "\n    <MailberryForm.Submit submitStyles={{ button: { width: '100%' }}} text='").concat(content.button, "' />\n  </MailberryForm>");
    }
    else {
        return "<MailberryForm formId='".concat(form.id, "' formContainerStyles={{ background: 'aliceblue }} thanksMessage='").concat(content.thanksMessage, "' format='").concat(format, "' ").concat(signature, " >\n    <MailberryForm.Description>\n      <h2 style={{ textAlign: 'center }}>").concat(content.header, "</h2>\n      ").concat(!!content.description && "<p>".concat(content.description, "</p>"), "\n      <hr />\n    </MailberryForm.Description>\n    <MailberryForm.FieldError />\n    ").concat(inputFields, "\n    <MailberryForm.Submit submitStyles={{ button: { width: '100%' }}} text='").concat(content.button, "' />\n  </MailberryForm>\n    ");
    }
};
