import { Preferences } from '@capacitor/preferences';
import { Session } from './indexedDB';

// On mobile it will use native storage , on web will falback to localstorage , but be aware that service worker can't acced to localstorage

const insertSession = async (session: Session) => {
  await Preferences.set({
    key: 'session',
    value: JSON.stringify(session),
  });
};

const getSession = async (): Promise<Session | null> => {
  const { value } = await Preferences.get({ key: 'session' });
  if (value) {
    return JSON.parse(value);
  }
  return null;
};

const deleteAll = async () => {
  await Preferences.remove({ key: 'session' });
};

const updateSession = async (session: Partial<Omit<Session, 'email'>>) => {
  const previousSession = await getSession();
  if (previousSession) {
    await Preferences.set({
      key: 'session',
      value: JSON.stringify({ ...previousSession, ...session }),
    });
  }
};

export default {
  insertSession,
  getSession,
  deleteAll,
  updateSession,
};
