import { Web } from '@/sharedTypes';
import { genericAuthRequest, useMailberryMutate } from './base';

export function usePresignUrlPrivateResource(onSuccess?: () => void, onError?: () => void) {
  return useMailberryMutate<Web.Response.SignedUrlResponse, Web.Request.PresignUrl>({
    f: (accessToken, userData) => {
      return genericAuthRequest(accessToken, 'get', `/asset/presignUrlPrivateResource`, userData);
    },
    options: { onSuccess, onError },
  });
}

export function usePresignUrlPublicResource(onSuccess?: () => void, onError?: () => void) {
  return useMailberryMutate<Web.Response.SignedUrlResponse, Web.Request.PresignUrl>({
    f: (accessToken, userData) => {
      return genericAuthRequest(accessToken, 'get', `/asset/presignUrlPublicResource`, userData);
    },
    options: { onSuccess, onError },
  });
}

export function useUploadCSV(onSuccess?: () => void, onError?: () => void) {
  return useMailberryMutate<[], Web.Request.UploadCSV>({
    f: (accessToken, userData) => {
      return genericAuthRequest(accessToken, 'post', `/asset/csv/upload`, userData);
    },
    options: { onSuccess, onError },
  });
}

export function useGetAllAssets(onSuccess?: () => void, onError?: () => void) {
  return useMailberryMutate<Web.Response.GetAllAssetsByCustomerId, { folder: string; }>({
    f: (accessToken, userData) => {
      return genericAuthRequest(accessToken, 'get', `/asset/getAllAssets`, userData);
    },
    options: { onSuccess, onError },
  });
}

export function useAddAccountAsset(onSuccess?: () => void, onError?: () => void) {
  return useMailberryMutate<Web.Response.GetAssetById, Web.Request.CreateAsset>({
    f: (accessToken, userData) => {
      return genericAuthRequest(accessToken, 'post', `/asset/`, userData);
    },
    options: { onSuccess, onError },
  });
}

export function useSignPrivateUrls(onSuccess?: () => void, onError?: () => void) {
  return useMailberryMutate<string[], { urls: string[]; }>({
    f: (accessToken, userData) => {
      return genericAuthRequest(accessToken, 'post', `/asset/signPrivateUrls`, userData);
    },
    options: { onSuccess, onError },
  });
}

export function useDeleteAsset(onSuccess?: () => void, onError?: () => void) {
  return useMailberryMutate<any, any>({
    f: (accessToken, userData) => {
      return genericAuthRequest(accessToken, 'delete', `/asset/${userData.id}`, userData);
    },
    options: { onSuccess, onError },
  });
}
