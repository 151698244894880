import TabMenuButton from '@components/screens/TabsMenu/TabsMenuButton';
import { Group, Text } from '@mantine/core';
import React from 'react';
import { FaChartBar } from 'react-icons/fa';
import { FiBookOpen } from 'react-icons/fi';
import { TiMessages } from 'react-icons/ti';

export default function DemoTabsMenu() {
  return (
    <Group
      align='center'
      // justify={appState.installed.platform === 'ios' ? 'flex-end' : 'center'}
      justify={'center'}
      style={{ marginLeft: 'auto', marginRight: 'auto' }}
    >
      <TabMenuButton route={'/demo'}>
        <FiBookOpen size={24} />
        <Text>Overview</Text>
      </TabMenuButton>
      <TabMenuButton route={'/chat'} disabled={true}>
        <TiMessages size={24} />
        <Text>Chat</Text>
      </TabMenuButton>
      <TabMenuButton route={'/reports'} disabled={true}>
        <FaChartBar size={24} />
        <Text>Reports</Text>
      </TabMenuButton>
    </Group>
  );
}
