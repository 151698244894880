import { DemoDraft, Draft } from '@prisma/client';
import { genericAuthRequest, genericRequest, RuntimeBackend, useMailberryQuery } from '../base';

export function useGetDemoDraft(demoDraftId: string) {
  return useMailberryQuery<{ data: DemoDraft; }>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/demo/draft`, { demoDraftId }, RuntimeBackend);
    },
    queryKey: [demoDraftId],
    options: {
      cacheTime: 0,
    },
  });
}
