import MailberryPaper from '@components/display/MailberryPaper';
import { Center, Checkbox, Container, Group, Skeleton, Space, Stack, Table } from '@mantine/core';
import React from 'react';

interface MBTableSkeletonProps {
  rows: rows[];
  manySelects?: number;
  checkbox?: boolean;
  title?: string;
}

interface rows {
  label: string;
  w?: number;
}

export const MBTableSkeleton = ({ title, rows, manySelects, checkbox }: MBTableSkeletonProps) => {
  // const { classes } = useStyles();
  return (
    <Stack gap={0} p={40}>
      {title ? <h2>{title}</h2> : <Skeleton h={40} w={200} />}
      <Space h={24} />
      <Group style={{ width: '100%' }}>
        {manySelects
          && Array(manySelects)
            .fill(null)
            .map((_, index) => {
              return <Skeleton key={index} h={48} w={232} />;
            })}
      </Group>

      <Space h={24} />
      <Center>
        <Table horizontalSpacing='xl' verticalSpacing='md' fs='sm' style={{ overflowY: 'scroll' }}>
          <Table.Thead>
            <Table.Tr>
              {checkbox && (
                <Table.Th style={{ width: 40 }}>
                  <Checkbox disabled />
                </Table.Th>
              )}
              {rows.map((row, index) => {
                return <Table.Th key={index}>{row.label}</Table.Th>;
              })}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {Array(20)
              .fill(null)
              .map((_, index) => {
                return (
                  <Table.Tr key={index} style={{ cursor: 'pointer' }}>
                    {checkbox && (
                      <Table.Td>
                        <Checkbox disabled />
                      </Table.Td>
                    )}
                    {rows.map((row, index) => (
                      <Table.Td key={index}>
                        <Skeleton h={40} w={row.w ? row.w : 200} />
                      </Table.Td>
                    ))}
                  </Table.Tr>
                );
              })}
          </Table.Tbody>
        </Table>
      </Center>
      <Space h='xl' />
    </Stack>
  );
};

// const useStyles = createStyles(theme => ({
//   table: {
//     overflowY: 'scroll',
//   },
//   tableRow: {
//     cursor: 'pointer',
//   },
// }));
