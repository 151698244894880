import { fixDecimal } from '@lib/utils';
import { Card, Group, Skeleton, Stack, Text, Title } from '@mantine/core';
import React from 'react';

export default function OverallStats({ account, isLoading }) {
  if (isLoading || !account) {
    return (
      <Group align='center' gap='xl' justify='start' w={'100%'} p={'lg'}>
        <Stack gap={0} w={'100%'}>
          <Skeleton height={28} width={'100%'} pb={'md'} />
          <Group gap='sm' align='center' justify='center' grow w={'100%'}>
            <Card shadow='sm' padding={'lg'} radius={'md'} withBorder>
              <Skeleton height={18} width={'70%'} />
              <Skeleton height={30} width={'40%'} />
            </Card>
            <Card shadow='sm' padding={'lg'} radius={'md'} withBorder>
              <Skeleton height={18} width={'70%'} />
              <Skeleton height={30} width={'40%'} />
            </Card>
          </Group>
        </Stack>
      </Group>
    );
  }

  return (
    <Group align='center' gap='xl' justify='start' w={'100%'} p={'lg'}>
      <Stack gap={0} w={'100%'}>
        <Title order={4} fw={600} pb={'md'}>
          Overall Stats
        </Title>
        <Group gap='sm' align='center' justify='center' grow w={'100%'}>
          <Card shadow='sm' padding={'lg'} radius={'md'} withBorder>
            <Text size={'1em'} style={{ whiteSpace: 'nowrap', overflow: 'visible' }}>Campaigns Sent</Text>
            <Title order={2}>{account.campaingsSent}</Title>
          </Card>
          <Card shadow='sm' padding={'lg'} radius={'md'} withBorder>
            <Text size={'1em'} style={{ whiteSpace: 'nowrap', overflow: 'visible' }}>Average Open Rate</Text>
            <Title order={2}>{fixDecimal(account.averageOpenRate, 1)}%</Title>
          </Card>
        </Group>
      </Stack>
    </Group>
  );
}
