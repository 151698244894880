import { Pagination } from '@mantine/core';
import React, { useEffect, useState } from 'react';

interface IusePaginationReturn {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  limit: number;
  offset: number;
  totalPages: number;
}

export function usePagination(pageSize: number, total: number | undefined): IusePaginationReturn {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(pageSize);
  const [offset, setOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (total) {
      setOffset(0 + (page - 1) * pageSize);
      setTotalPages(Math.ceil(total / pageSize));
    }
  }, [pageSize, total, page]);

  return {
    page,
    totalPages,
    setPage,
    limit,
    offset,
  };
}
