import { Stack } from '@mantine/core';
import { memo } from 'react';

type Props = {
  html: string | JSX.Element | JSX.Element[];
  style?: React.CSSProperties;
};

export const HTMLRendered: React.FC<Props> = memo(({ html, style }) => {
  return (
    <Stack style={style} gap={0}>
      {html}
    </Stack>
  );
});
