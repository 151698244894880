import { Box, Group, Stack } from '@mantine/core';
import Image from 'next/image';
import React from 'react';
import styles from './MailberryLoader.module.css';

export const MailberryLoader = ({ visible = true }) => {
  return (
    <Box h='100%' w='100%' pos={'absolute'} display={visible ? 'flex' : 'none'}>
      <Group className={styles.rootBox} style={{}} bg='rgba(255, 255, 255, 0.85)'>
        <Stack className={styles.stack} align='center' justify='center'>
          <img className='heartBeat-animation' width={'20%'} height={'20%'} src='/assets/mailberry-logo-simple.svg' alt='Mailberry Logo' />
        </Stack>
      </Group>
    </Box>
  );
};
