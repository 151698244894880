// import { filterObjectsById } from '@/lib/utils';
// import { initNotifications } from '@/services/pushNotifications';
import BerryIcon from '@/assets/icons/BerryIcon';
import MessageBox from '@/components/screens/Chat/MessageBox';
import { useMarkAsRead } from '@/services/api/messages';
import useInView from '@/services/hooks/useInView';
import PersistentStorage from '@/services/storage';
import { MESSAGE_AUTHOR } from '@/types/models';
import { Box, Center, Divider, Group, Loader, Text } from '@mantine/core';
import { useEffect, useRef } from 'react';
import { NewMessagesIndicator } from './NewMessagesIndicator';

// const updateMessagesLocal = async (messages: any[]) => {
//   if (!messages || messages.length === 0) return;
//   const session = await PersistentStorage.getItem();
//   const sortedAndUnique = sortMessagesAndRemoveDuplicates(messages);
//   await PersistentStorage.updateItem(session.email, {
//     chat: { ...session.chat, messages: sortedAndUnique },
//   });
// };

// const addUnreadMessagesLocal = async (messages: any) => {
//   if (!messages || messages.length === 0) return;
//   const session = await PersistentStorage.getItem();
//   const sortedAndUnique = session.chat.messages
//     ? sortMessagesAndRemoveDuplicates([...session.chat.unreadMessages, ...messages])
//     : sortMessagesAndRemoveDuplicates(messages);
//   await PersistentStorage.updateItem(session.email, {
//     chat: { ...session.chat, unreadMessages: sortedAndUnique },
//   });
// };

interface IMessagesList {
  styles?: any;
  newMessages: any;
  readMessages: any;
  isMobile: boolean;
  isLoading: boolean;
  isFetchingNextPage: boolean;
  addPastMessagesState: (messages: any) => void;
  addPastMessagesLocal: (messages: any) => void;
  messagesPerRequest: number;
  readMessagesPure: any;
}

const MessagesList = (
  { styles, newMessages, readMessages, isMobile, isLoading, isFetchingNextPage, addPastMessagesState, messagesPerRequest, addPastMessagesLocal, readMessagesPure }: IMessagesList,
) => {
  const markAsReadServer = useMarkAsRead();

  const newMessagesRef = useRef<HTMLDivElement>(null);
  const newMessagesInView = useInView(newMessagesRef);
  const messagesBottomRef = useRef<HTMLDivElement>(null);
  const messagesTopRef = useRef<HTMLDivElement>(null);

  const messagesTopRefIsInView = useInView(messagesTopRef);
  const isBottomInView = useInView(messagesBottomRef);

  const handleScrollToNewMessages = () => {
    if (!newMessagesInView) {
      messagesBottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const getMessagesLocal = async () => {
    const session = await PersistentStorage.getItem();
    return session?.chat.messages || [];
  };

  // const loadFromLocal = async () => {
  //   const localMessages = await getMessagesLocal();
  //   const messagesSubset = localMessages.slice(0, messagesPerRequest);
  //   console.log('messagesSubset', messagesSubset);
  //   if (localMessages.length > 0) {
  //     addPastMessagesLocal(messagesSubset);
  //   } else {
  //     throw new Error('No messages found in local storage');
  //   }
  // };

  // const loadFromServer = async () => {
  //   console.log('Loading from server', readMessagesPages);
  //   fetchNextPage();
  //   if (readMessagesPages?.pages[0].totalPages < 1) {
  //     setLastMessageId('all');
  //   }
  //   if (readMessagesPages) {
  //     addPastMessagesLocal(readMessagesPages.pages.slice(1).flatMap(e => e.messages));
  //     setReadMessages(readMessagesPages.pages[0].messages);
  //     console.log('Loaded from server');
  //   }
  // };

  useEffect(() => {
    // try loading past messages from local storage first before fetching from server
    if (messagesTopRefIsInView) {
      console.log('messagesTopRefIsInView', messagesTopRefIsInView);

      const loadPastMessages = async () => {
        console.log('Loading past messages');
        const localMessages = await getMessagesLocal();
        if (localMessages.length > readMessages.length) {
          console.log('Loading from local storage', localMessages.length, readMessages.length);
          const subset = localMessages.slice(readMessages.length, readMessages.length + messagesPerRequest);
          addPastMessagesState(subset);
        } else {
          console.log('No more messages to load');
          addPastMessagesState(readMessagesPure?.messages);
          addPastMessagesLocal(readMessagesPure?.messages);
        }
      };

      loadPastMessages();
    }
  }, [messagesTopRefIsInView]);

  useEffect(() => {
    if (isBottomInView) {
      markAsReadServer.mutateAsync({});
    }
  }, [isBottomInView]);

  // useEffect(() => {
  //   const loadFromLocal = async () => {
  //     const session = await PersistentStorage.getItem();
  //     const localMessages = session?.chat || [];
  //     setReadMessages(localMessages);
  //     setLoadedFromLocal(true);
  //   };

  //   loadFromLocal();
  // }, []);

  // // useEffect(() => {
  // //   if (appState.signupAndLogin.accessToken) {
  // //     console.log('Init notifications');
  // //     initNotifications(appState.signupAndLogin.accessToken);
  // //   }
  // // }, []);

  // const sendMessage = useSendMessage();
  // const markAsRead = useMarkAsRead();

  // // When the chat is opened, the scroll moves to the last message
  // const messagesBottomRef = useRef<HTMLDivElement>(null);

  // const newMessagesRef = useRef<HTMLDivElement>(null);
  // const newMessagesInView = useInView(newMessagesRef);

  // const [readMessages, setReadMessages] = useState<any>([]);

  // const updatePastMessages = (pastMessages: any) => {
  //   const filtered = [...filterObjectsById(readMessages, pastMessages), ...readMessages];
  //   const sorted = filtered.sort((a: any, b: any) => {
  //     return new Date(b.sentAt).getTime() - new Date(a.sentAt).getTime();
  //   });
  //   setReadMessages(sorted);
  // };

  // useEffect(() => {
  //   if (newMessagesInView) {
  //     markAsRead.mutateAsync({});
  //   }
  // }, [newMessagesInView]);

  // useEffect(() => {
  //   messagesBottomRef.current?.scrollIntoView({ behavior: 'instant' });
  // }, [readMessagesPages?.pages[0].page]);

  // // Request more messages by scrolling up
  // const messagesTopRef = useRef<HTMLDivElement>(null);
  // const isInView = useInView(messagesTopRef);

  // useEffect(() => {
  //   if (isInView) {
  //     console.log('VISTO');
  //     fetchNextPage();
  //   }
  // }, [isInView]);

  // useEffect(() => {
  //   console.log('ratata', readMessagesPages?.pages, newMessages.length);
  //   if (readMessages.length === 0 && readMessagesPages) {
  //     setReadMessages(readMessagesPages.pages.flatMap(e => e.messages));
  //   } else if (readMessagesPages) {
  //     updatePastMessages(readMessagesPages.pages.slice(1).flatMap(e => e.messages));
  //   }
  //   if (readMessagesPages?.pages[0].totalPages < 1 && newMessages.length < 1) {
  //     setLastMessageId('all');
  //   }
  // }, [readMessagesPages]);

  // let _newMessages = useGetNewMessages(lastMessageId);

  // const updateLastMessageId = (readMessages: any) => {
  //   if (readMessages && readMessages.length > 0) {
  //     setLastMessageId(readMessages[0].id);
  //   }
  // };

  // useEffect(() => {
  //   if (readMessages) {
  //     updateLastMessageId(readMessages);
  //     // make a copy of newMessages
  //   }
  // }, [readMessages]);

  // useEffect(() => {
  //   if (_newMessages.data !== newMessages?.data) {
  //     if (newMessages?.data) {
  //       const newMessagesCopy = { ..._newMessages };
  //       const readMessagesCopy = [...readMessages];
  //       // filter out the messages that are already in readMessages
  //       const filteredNewMessages = filterObjectsById(readMessagesCopy, newMessagesCopy?.data);
  //       // set newMessages to the filtered messages
  //       newMessagesCopy.data = filteredNewMessages;
  //       setNewMessages(newMessagesCopy);
  //       return;
  //     }
  //     setNewMessages(_newMessages);
  //   }
  // }, [_newMessages.data]); // Only trigger when _newMessages.data changes

  // const isNewMessagesInView = useInView(newMessagesRef);

  // useEffect(() => {
  //   if (isNewMessagesInView && newMessages?.data?.length > 0) {
  //     markAsRead.mutateAsync({});
  //   }
  // }, [isNewMessagesInView]);

  // const handleScrollToNewMessages = () => {
  //   if (!newMessagesInView) {
  //     messagesBottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };

  return (
    <>
      <NewMessagesIndicator
        newMessages={newMessages}
        newMessagesInView={newMessagesInView}
        handleScrollToNewMessages={handleScrollToNewMessages}
      />
      <Box
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column-reverse',
          gap: '0.5rem',
          padding: '1rem',
          position: 'relative',
          overflowY: 'auto',
          // width: '80%',
        }}
        pb={0}
        px={isMobile ? '' : '25%'}
      >
        <Box ref={messagesBottomRef}></Box>
        <Box ref={newMessagesRef}></Box>
        {!newMessages?.isLoading && (
          <>
            {newMessages?.map((m, i) => (
              <MessageBox
                key={i}
                message={m.text}
                time={m.sentAt}
                isSender={m.createdBy !== MESSAGE_AUTHOR.MB_PERSONNEL}
                type={m.type}
                metadata={m.metadata}
              />
            ))}

            {newMessages?.length > 0 && (
              <Divider
                my='xs'
                labelPosition='center'
                label={
                  <Group gap='xs' align='center'>
                    <BerryIcon style={{ width: 12, height: 12, fill: '#6B7280' }} />
                    <Text size='sm' c='gray'>
                      New Messages
                    </Text>
                  </Group>
                }
              />
            )}
          </>
        )}
        {!isLoading && readMessages.length < 1 && newMessages.length < 1
          ? (
            <Group justify='center'>
              <Text>Type something to start a conversation</Text>
            </Group>
          )
          : (
            readMessages?.map((m, i) => (
              <MessageBox
                key={i}
                message={m.text}
                time={m.sentAt}
                isSender={m.createdBy !== MESSAGE_AUTHOR.MB_PERSONNEL}
                type={m.type}
                metadata={m.metadata}
              />
            ))
          )}

        {isFetchingNextPage && (
          <Center>
            <Loader size={24} color='gray' />
          </Center>
        )}

        <Box
          ref={messagesTopRef}
          style={{
            height: 240,
            width: '100%',
          }}
        />
      </Box>
    </>
  );
};

export default MessagesList;
